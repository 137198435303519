<template>
    <ApplicationForm v-if="isLoaded==1" :validationErrors="validationErrors" :applicationData="applicationData" :patientProfile="profile" @trigger-previous="goPrevious()" @trigger-next="saveApplication()" />
</template>

<script>
    import ApplicationForm from "../CommonElements/ApplicationForm";
    export default {
        components: {
            ApplicationForm,
        },
        data(){
            return {
                isLoaded:0,
                profile:{},
                validationErrors:{},
                applicationData : {
                    patientId:this.$store.getters.getRecentTestApplicationPatientID,
                    applicationId:this.$store.getters.getRecentTestApplicationID,
                    testType:'',
                    proofType:'insurance',
                    ssnNo:'',
                    drivingLicenseNo:'',
                    insuranceName:'',
                    insuranceMemberNo:'',
                    insuranceGroupNo:'',
                    insurancePhoneNo:'',
                    insurancePolicyHolder:'',
                    insuranceProof1:null,
                    insuranceProof2:null,
                    insuranceProof1DownloadLink:'',
                    insuranceProof2DownloadLink:'',
                    pregnancyStatus:''
                }
            }
        },
        mounted(){
            if(!this.$store.getters.isTestApplicationLoaded){
                this.$router.push("/test/profile");
            }
            this.loadApplication();
            this.getPatientProfile();
        },
        methods:{
            getPatientProfile(){
                axios.get(this.$store.getters.getAPIBasePath + '/patientprofiles/view/'+this.applicationData.patientId)
                .then(response => {
                    this.profile = response.data.recordinfo;
                })
                .catch(error => {
                    console.log(error);
                });
            },
            loadApplication(){
                axios.get(this.$store.getters.getAPIBasePath + '/applications/view/'+this.applicationData.applicationId)
                .then(response => {
                    let appData = response.data.applicationPreview;
                    this.applicationData.testType = (appData.testType==null)?'':appData.testType;
                    this.applicationData.proofType = (appData.proofType==null)?'insurance':appData.proofType;
                    this.applicationData.pregnancyStatus = (appData.testType==null)?'':appData.testType;
                    this.applicationData.ssnNo = appData.ssnNo;
                    if(appData.proofType=='other'){
                        this.applicationData.drivingLicenseNo = appData.drivingLicenseNo;
                    }
                    else{
                        if(appData.insuranceProof1!=undefined){
                            this.applicationData.insuranceProof1DownloadLink = appData.insuranceProof1;
                        }
                        if(appData.insuranceProof2!=undefined){
                            this.applicationData.insuranceProof2DownloadLink = appData.insuranceProof2;
                        }
                        this.applicationData.insuranceName = appData.insuranceName;
                        this.applicationData.insuranceMemberNo = appData.insuranceMemberNo;
                        this.applicationData.insuranceGroupNo = appData.insuranceGroupNo;
                        this.applicationData.insurancePhoneNo = appData.insurancePhoneNo;
                        this.applicationData.insurancePolicyHolder = (appData.insurancePolicyHolder==null)?'':appData.insurancePolicyHolder;
                    }
                    this.isLoaded = 1;
                })
                .catch(error => {
                    console.log(error);
                });
            },
            saveApplication(){
                if(this.profile.gender==='Male'){
                    this.applicationData.pregnancyStatus="Unknown";
                }
                let fileUploadVars = ["insuranceProof1","insuranceProof2"];
                let formData = new FormData();
                let self = this;
                jQuery.each(this.applicationData, function( key, value ) {
                    if(value==undefined || value=="" || value==null || value=="undefined"){
                        if(!fileUploadVars.includes(key)){
                            //ignoring file attaching if it is null
                            formData.append(key, "");
                        }
                    }
                    else{
                        formData.append(key, value);
                    }
                });
                axios.post(this.$store.getters.getAPIBasePath + '/applications/save', formData,{
                    headers: {
                        'Content-Type': 'application/x-www-form-urlencoded'
                    }
			    })
                .then(response => {
                    this.$router.push('/test/application/preview');
                })
                .catch(error => {
                    if(!error.response.data.headers.length){
                        this.$awn.alert(error.response.data.headers.message);
                    }
                    if(!error.response.data.errorlist.length){
                        this.validationErrors = error.response.data.errorlist;
                        console.log(this.validationErrors);
                    }
                });
            },
            goPrevious(){
                this.$router.push("/test/questions");
            }
        }
    };
</script>