<template>
    <section>
        <div class="container">
            <form id="contact-form" method="post" role="form">
                <div class="text-center form-header">
                    <h3 class="form-heading">Test Registration</h3>
                </div>
                <div class="row justify-content-center">
                    <div class="col-md-6 col-sm-6">
                        <div class="form-group">
                            <label for="form_name">Select Patient *</label>
                            <select class="form-control custom-select" v-model="testApply.patientId" name="patientProfile" id="patientProfile">
                                <option v-for="(data) in patientProfiles" :key="data.id" :value="data.id">{{data.firstName}} {{data.lastName}}</option>
                            </select>
                            <div class="help-block with-errors"></div>
                        </div>
                    </div>
                </div>
                <div class="login-submit mt-2">
                    <input type="button" @click="loadApplication()" class="bttn bttn--primary" value="Next" />
                </div>
            </form>
        </div>
    </section>
</template>

<script>
    export default {
        data() {
            return {
			    patientProfiles:[],
                testApply:{
                    patientId:''
                }
            }
        },
        mounted(){
            this.loadPatientProfiles();
        },
        methods:{
            loadPatientProfiles() {
                axios.get(this.$store.getters.getAPIBasePath + '/patientprofiles')
                .then(response => {
                    let profiles = response.data.data;
                    this.patientProfiles = profiles;
                    if(profiles.length>0){
                        if(this.$store.getters.isTestApplicationLoaded){
                            this.testApply.patientId = this.$store.getters.getRecentTestApplicationPatientID;    
                        }
                        else{
                            this.testApply.patientId = profiles[0].id;
                        }                        
                    }
                })
                .catch(error => {
                    console.log(error);
                });
            },
            loadApplication(){
                axios.post(this.$store.getters.getAPIBasePath + '/applications/new',this.testApply)
                .then(response => {
                    let appData = response.data.applicationInfo;
                    this.$store.commit("saveRecentTestApplicationID",appData.id);
                    this.$store.commit("saveRecentTestApplicationPatientID",appData.patientId);
                    this.$router.push("/test/fdadisclaimer");
                })
                .catch(error => {
                    if(!error.response.data.headers.length){
                        this.$awn.alert(error.response.data.headers.message);
                    }
                });
            }
        }
    };
</script>