<template>
    <FactSheet @trigger-next="goNext()" @trigger-previous="goPrevious()" />
</template>

<script>
    import FactSheet from "../CommonElements/FactSheet";
    export default {
        components: {
            FactSheet,
        },
        mounted(){
            if(!this.$store.getters.isTestApplicationLoaded){
                this.$router.push("/test/profile");
            }
        },
        methods:{
            goPrevious(){
                this.$router.push("/test/fdadisclaimer");
            },
            goNext(){
                this.$router.push("/test/questions");
            }
        }
    };
</script>