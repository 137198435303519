<template>
    <section class="application">
        <div class="container">
            <div class="question">
                <div class="question__header">
                    <h4>Change Password</h4>
                </div>
                <div class="question__body">
                    <div class="row">
                        <div class="col-md-4 col-sm-6">
                            <div class="form-group">
                                <label for="current_password">Current Password *</label>
                                <input v-model="changePassword.password" id="current_password" type="password" name="current_password" class="form-control" placeholder="Please enter your current Password">
                                <div v-if="validationErrors.password != undefined" class="mt-1 mb-2 vuelidate-error">{{ validationErrors.password }}</div>
                            </div>
                        </div>
                        <div class="col-md-4 col-sm-6">
                            <div class="form-group">
                                <label for="new_password">New Password *</label>
                                <input v-model="changePassword.newPassword" id="new_password" type="password" name="new_password" class="form-control" placeholder="Please enter your new Password">
                                <div v-if="validationErrors.newPassword != undefined" class="mt-1 mb-2 vuelidate-error">{{ validationErrors.newPassword }}</div>
                            </div>
                        </div>
                        <div class="col-md-4 col-sm-6">
                            <div class="form-group">
                                <label for="confirm_password">Confirm Password *</label>
                                <input v-model="changePassword.confirmPassword" id="confirm_password" type="password" name="confirm_password" class="form-control" placeholder="Please confirm your new Password">
                                <div v-if="validationErrors.confirmPassword != undefined" class="mt-1 mb-2 vuelidate-error">{{ validationErrors.confirmPassword }}</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="text-center mb-5">
            <button type="button" class="bttn bttn--primary mx-2" @click="processChangePassword">Change Password</button>
        </div>
    </section>
</template>
<script>
export default {
    data() {
        return {
            changePassword:{
                password:'',
                newPassword:'',
                confirmPassword:''
            },
            validationErrors:{}
        }
    },
    mounted() {
        
    },
    methods: {
        processChangePassword(){
            this.validationErrors = {};
            axios.post(this.$store.getters.getAPIBasePath + '/profile/changepassword', this.changePassword)
            .then(response => {
                this.$awn.success(response.data.headers.message);
                this.$router.push("/dashboard");
            })
            .catch(error => {
                if(!error.response.data.headers.length){
                    this.$awn.alert(error.response.data.headers.message);
                }
                if(!error.response.data.errorlist.length){
                    this.validationErrors = error.response.data.errorlist;
                }
            });
        }
    }
}
</script>