<template>
    <section>
        <div class="container">
            <div class="text-center">
                <h4>Instruction Sheet</h4>
            </div>
            <div class="row justify-content-md-center text-center">
                <div class="col col-lg-4">
                    <a href="https://vvdocuments.s3.us-east-2.amazonaws.com/Instruction-Sheet/instruction-sheet.pdf" target="_blank" class="navbar-brand"><img src="https://vvdocuments.s3.us-east-2.amazonaws.com/Instruction-Sheet/instructions.png" alt="img" class="img-fluid" width="250px"></a>
                </div>
            </div>
            <div class="row justify-content-md-center text-center">
                <div class="col col-lg-4">
                    <a href="https://vvdocuments.s3.us-east-2.amazonaws.com/Instruction-Sheet/instruction-sheet.pdf" target="_blank">Click here to Download</a>
                </div>
            </div>
            <div class="text-center mb-5 mt-7">
                <button type="button" @click="triggerPreviousAction()" class="bttn bttn--secondary mx-2">Back</button>
                <button type="button" @click="triggerNextAction()" class="bttn bttn--primary mx-2">Next</button>
            </div>
        </div>
    </section>
</template>

<script>
    export default {
        methods:{
            triggerNextAction(){
                this.$emit('trigger-next');
            },
            triggerPreviousAction(){
                this.$emit('trigger-previous');
            }
        }
    };
</script>