<template>
    <div class="container">
        <form id="contact-form" @submit.prevent="onFormSubmit()" method="post" role="form">
            <h3 class="form-heading">Enter Patient Information Below:</h3>
            <div class="messages"></div>
            <div class="row">
                <div class="col-md-4 col-sm-6">
                    <div class="form-group">
                        <label for="firstname">First Name *</label>
                        <input id="firstname" type="text" name="firstname" v-model="patientInfo.firstName" class="form-control" placeholder="Please enter your first name *">
                        <div v-if="validationErrors.firstName != undefined" class="mt-1 mb-2 vuelidate-error">{{ validationErrors.firstName }}</div>
                    </div>
                </div>
                <div class="col-md-4 col-sm-6">
                    <div class="form-group">
                        <label for="middlename">Middle Name</label>
                        <input id="middlename" type="text" name="middlename" v-model="patientInfo.middleName" class="form-control" placeholder="Please enter your middle name">
                        <div v-if="validationErrors.middleName != undefined" class="mt-1 mb-2 vuelidate-error">{{ validationErrors.middleName }}</div>
                    </div>
                </div>
                <div class="col-md-4 col-sm-6">
                    <div class="form-group">
                        <label for="lastname">Last Name *</label>
                        <input id="lastname" type="text" name="lastname" v-model="patientInfo.lastName" class="form-control" placeholder="Please enter your last name *">
                        <div v-if="validationErrors.lastName != undefined" class="mt-1 mb-2 vuelidate-error">{{ validationErrors.lastName }}</div>
                    </div>
                </div>
                <div class="col-md-4 col-sm-6">
                    <div class="form-group">
                        <label for="form_phone">Phone *</label>
                        <input-mask class="form__input form-control" v-model="patientInfo.phoneNo" mask="(999)-999-9999" placeholder="Please enter your phone number" maskChar="_"></input-mask>
                        <div v-if="validationErrors.phoneNo != undefined" class="mt-1 mb-2 vuelidate-error">{{ validationErrors.phoneNo }}</div>
                    </div>
                </div>
                <div class="col-md-4 col-sm-6">
                    <div class="form-group">
                        <label for="form_name">Gender *</label>
                        <select class="form-control custom-select" v-model="patientInfo.gender" name="gender" id="gender">
                            <option value="">Select</option>
                            <option v-for="(data) in genders" :key="data.key" :value="data.key">{{data.label}}</option>
                        </select>
                        <div v-if="validationErrors.gender != undefined" class="mt-1 mb-2 vuelidate-error">{{ validationErrors.gender }}</div>
                    </div>
                </div>
                <div class="col-md-4 col-sm-6">
                    <div class="form-group">
                        <label for="dob">DOB *</label>
                        <input-mask class="form__input form-control" v-model="patientInfo.dob" mask="99-99-9999" placeholder="MM-DD-YYYY" maskChar="_"></input-mask>
                        <div v-if="validationErrors.dob != undefined" class="mt-1 mb-2 vuelidate-error">{{ validationErrors.dob }}</div>
                    </div>
                </div>
                <div class="col-md-4 col-sm-6">
                    <div class="form-group">
                        <label for="patient_relationship">Patient Relationship *</label>
                        <select class="form-control custom-select w-100" v-model="patientInfo.patientRelationship" name="patient_relationship" id="patient_relationship">
                            <option value="">Select</option>
                            <option v-for="(data) in patientRelations" :key="data.key" :value="data.key">{{data.label}}</option>
                        </select>
                        <div v-if="validationErrors.patientRelationship != undefined" class="mt-1 mb-2 vuelidate-error">{{ validationErrors.patientRelationship }}</div>
                    </div>
                </div>
                <div class="col-md-4 col-sm-6">
                    <div class="form-group">
                        <label for="form_name">Race *</label>
                        <select class="form-control custom-select w-100" v-model="patientInfo.race" name="race" id="race">
                            <option value="">Select</option>
                            <option v-for="(data) in races" :key="data.key" :value="data.key">{{data.label}}</option>
                        </select>
                        <div v-if="validationErrors.race != undefined" class="mt-1 mb-2 vuelidate-error">{{ validationErrors.race }}</div>
                    </div>
                </div>
                <div class="col-md-4 col-sm-6">
                    <div class="form-group">
                        <label for="dob">Ethnicity *</label>
                        <select class="form-control custom-select w-100" v-model="patientInfo.ethnicity" name="ethnicity" id="ethnicity">
                            <option value="">Select</option>
                            <option v-for="(data) in ethnicities" :key="data.key" :value="data.key">{{data.label}}</option>
                        </select>
                        <div v-if="validationErrors.ethnicity != undefined" class="mt-1 mb-2 vuelidate-error">{{ validationErrors.ethnicity }}</div>
                    </div>
                </div>
                <div class="col-md-12">
                    <div class="form-group">
                        <label for="address">Address *</label>
                        <vue-google-autocomplete
                            ref="address"
                            id="map"
                            classname="form-control"
                            placeholder="Please type your address"
                            v-on:placechanged="getAddressData"
                            v-model="address"
                            country="us"
                            >
                        </vue-google-autocomplete>
                        <!-- <input type="text" id="address" name="address" v-model="patientInfo.address" class="form-control" placeholder="Please enter your address *" rows="4"> -->
                        <div v-if="validationErrors.address != undefined" class="mt-1 mb-2 vuelidate-error">{{ validationErrors.address }}</div>
                    </div>
                </div>
                <div class="col-md-4 col-sm-6">
                    <div class="form-group">
                        <label for="city">City *</label>
                        <input id="city" type="text" name="city" v-model="patientInfo.city" class="form-control" placeholder="Please enter your City *">
                        <div v-if="validationErrors.city != undefined" class="mt-1 mb-2 vuelidate-error">{{ validationErrors.city }}</div>
                    </div>
                </div>
                <div class="col-md-4 col-sm-6">
                    <div class="form-group">
                        <label for="state">State *</label>
                        <input id="state" type="text" name="state" v-model="patientInfo.state" class="form-control" placeholder="Please enter your State">
                        <div v-if="validationErrors.state != undefined" class="mt-1 mb-2 vuelidate-error">{{ validationErrors.state }}</div>
                    </div>
                </div>
                <div class="col-md-4 col-sm-6">
                    <div class="form-group">
                        <label for="zipcode">Zipcode *</label>
                        <input id="zipcode" type="text" name="zipcode" v-model="patientInfo.zipCode" class="form-control" placeholder="Please enter your Zipcode *">
                        <div v-if="validationErrors.zipCode != undefined" class="mt-1 mb-2 vuelidate-error">{{ validationErrors.zipCode }}</div>
                    </div>
                </div>
            </div>
            <div class="login-submit">
                <input type="submit" class="bttn bttn--primary" value="Submit" />
            </div>
        </form>
    </div>
</template>

<script>
    import VueGoogleAutocomplete from 'vue-google-autocomplete';
    export default {
        components: { VueGoogleAutocomplete },
        props: {
            patientInfo: Object,
            validationErrors: Object
        },
        data(){
            return {
                genders:this.$store.getters.getGenders,
                patientRelations:this.$store.getters.getPatientRelations,
                races:this.$store.getters.getRaces,
                ethnicities:this.$store.getters.getEthnicities,
                address: ''
            }
        },
        methods:{
            onFormSubmit(){
                this.$emit("on-submit");
            },
            getAddressData: function (addressData, placeResultData, id) {
                this.address = addressData;
                this.patientInfo.address = this.address.street_number + ' ' + this.address.route;
                this.patientInfo.city = this.address.locality;
                this.patientInfo.state = this.address.administrative_area_level_1;
                this.patientInfo.zipCode = this.address.postal_code;
            }
        },
        mounted() {
            this.address = this.patientInfo.address;
        },
    }
</script>