<template>
    <section class="question__section" v-if="questions.length>0">
        <div class="container">
            <div v-for="(data,index) in questions" :key="index" class="question">
                <div class="question__header">
                    <h4>{{data.question}}</h4>
                </div>
                <div class="question__body">
                    <div class="row" v-if="data.isGrouped==0">
                        <div v-for="(answer,ansindex) in data.answers" :key="ansindex" class="col-md-12 question__answer">
                            <input 
                            type="checkbox" 
                            :name="data.id.toString()+'-'+answer.answer" 
                            :id="data.id.toString()+'-'+answer.answer" 
                            :checked="answers.hasOwnProperty(data.id.toString()) && answers[data.id.toString()].includes(answer.answer) ? true: false"
                            @click="addAnswer(data.id,answer.answer,answer.is_critical)">
                            <label :for="data.id.toString()+'-'+answer.answer">{{answer.answer}}</label>
                        </div>
                    </div>
                    <div class="row" v-if="data.isGrouped==1">
                        <div v-for="(answer,ansindex) in data.answers" :key="ansindex" class="col-md-6 question__answer">
                            <input 
                            type="checkbox" 
                            :name="data.id.toString()+'-'+answer.answer" 
                            :id="data.id.toString()+'-'+answer.answer" 
                            :checked="answers.hasOwnProperty(data.id.toString()) && answers[data.id.toString()].includes(answer.answer) ? true: false"
                            @click="addAnswer(data.id,answer.answer,answer.is_critical)">
                            <label :for="data.id.toString()+'-'+answer.answer">{{answer.answer}}</label>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="text-center mb-5">
            <button type="button" class="bttn bttn--secondary mx-2" @click="triggerPreviousAction">Back</button>
            <button type="button" class="bttn bttn--primary mx-2" @click="triggerNextAction">Next</button>
        </div>
    </section>
</template>

<script>
    export default {
        props:{
            answers:Object
        },
        data(){
            return {
                questions:[]
            }
        },
        mounted(){
            this.loadQuestions();
            console.log(this.answers);
        },
        methods:{
            addAnswer(questionid,answer,isCritical){
                let answerIndex = questionid.toString();
                if(!this.answers.hasOwnProperty(answerIndex)){
                    this.answers[answerIndex] = [];
                }
                if(this.answers[answerIndex].includes(answer)){
                    this.answers[answerIndex] = this.answers[answerIndex].filter(item => item !== answer);
                }
                else{
                    if(isCritical==1){
                        //let notifier = new AWN();
                        let onOk = () => {};
                        //let onCancel = () => {notifier.info('You pressed Cancel')};
                        this.$awn.confirm(
                            'Please stop the survey immediately and call 911',
                            onOk,
                            false,
                            {
                            labels: {
                                confirm: 'Alert'
                            }
                            }
                        )
                    }
                    this.answers[answerIndex].push(answer);
                }
                if(this.answers[answerIndex].length==0){
                    delete this.answers[answerIndex];
                }
            },
            loadQuestions(){
                axios.get(this.$store.getters.getAPIBasePath + '/applications/getquestions')
                .then(response => {
                    this.questions = response.data.data;
                })
                .catch(error => {
                    console.log(error.response.data);
                });
            },
            triggerNextAction(){
                let invalid = 0;
                let self = this;
                jQuery.each(this.questions, function( index, data ) {
                    let answerIndex = data.id.toString();
                    if(!self.answers.hasOwnProperty(answerIndex)){
                        invalid = 1;
                    }
                });
                if(invalid==0){
                    console.log('emitting');
                    this.$emit('trigger-next');
                }
                else{
                    alert('Please select an Answer for all available questions');
                }
            },
            triggerPreviousAction(){
                this.$emit('trigger-previous');
            }
        }
    };
</script>