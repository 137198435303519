<template>
	<div data-app>
		<v-app :is="layout">
			<router-view></router-view>
		</v-app>
	</div>
</template>

<script>
export default {
	name: "App",
	computed: {
		layout() {
			return this.$route.meta.layout == "default"? "default-layout": "dashboard-layout";
		},
	},
};
</script>
