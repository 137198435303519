<template>
    <section>
        <PatientForm :patientInfo="patientInfo" :validationErrors="validationErrors" @on-submit="addPatientProfile"></PatientForm>
    </section>
</template>
<script>
import PatientForm from "./Elements/PatientForm";
import moment from 'moment';
export default {
    components: {
        PatientForm,
    },
    data(){
        return {
            userData:this.$store.getters.getUserData,
            patientInfo:{
                firstName:'',
                middleName:'',
                lastName:'',
                phoneNo:'',
                gender:'',
                dob:'',
                patientRelationship:'',
                race:'',
                ethnicity:'',
                address:'',
                city:'',
                state:'',
                zipCode:''
            },
            validationErrors:{}
        }
    },
    mounted(){
        this.loadPatientProfiles();
    },
    methods: {
        loadPatientProfiles() {
            axios.get(this.$store.getters.getAPIBasePath + '/patientprofiles')
            .then(response => {
				let profiles = response.data.data;
				if(!profiles.length){
					this.patientInfo.firstName = this.userData.firstName;
                    this.patientInfo.middleName = this.userData.middleName;
                    this.patientInfo.lastName = this.userData.lastName;
                    this.patientInfo.dob = moment(String(this.userData.dob)).format('MM-DD-YYYY');
				}
            })
            .catch(error => {
                console.log(error);
            });
        },
        addPatientProfile(){
            this.validationErrors = {};
            axios.post(this.$store.getters.getAPIBasePath + '/patientprofiles/add', this.patientInfo)
            .then(response => {
                this.$awn.success(response.data.headers.message);
                this.$router.push("/dashboard");
            })
            .catch(error => {
                if(!error.response.data.headers.length){
                    this.$awn.alert(error.response.data.headers.message);
                }
                if(!error.response.data.errorlist.length){
                    this.validationErrors = error.response.data.errorlist;
                }
            });
        }
    }
}
</script>
