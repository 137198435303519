window.axios = require('axios')
window.$ = require('jquery')
window.jQuery = require('jquery')

import 'bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import '../src/assets/css/style.css';
import 'vue-awesome-notifications/dist/styles/style.css';
import Vue from 'vue';
import Router from 'vue-router';
import Vuex from 'vuex';
import moment from 'moment';
import vuetify from './plugins/vuetify';
import Vuelidate from 'vuelidate';
import InputMask from 'vue-input-mask';
import VueAWN from "vue-awesome-notifications";

import routes from './router';
import storeData from './store';
import filters from './filters';

import App from './App.vue';
import Master from "./components/Layout/Master.vue";
import Default from "./components/Layout/Default.vue";

Vue.config.productionTip = false;

let vueAWNOptions = {
	position:'top-right',
	maxNotifications:3,
	animationDuration:500,
	icons:{
		enabled:true,
		success: "check",
	}
};

Vue.use(Router);
Vue.use(Vuex);
Vue.use(Vuelidate);
Vue.use(VueAWN, vueAWNOptions);

// moment.tz.setDefault("America/New_York");

for (let name in filters) {
    Vue.filter(name, filters[name]);
}

Vue.component('input-mask', InputMask);
Vue.component('dashboard-layout', Master);
Vue.component('default-layout', Default);


let router = new Router({
    routes,
    mode: 'history'
});

const store = new Vuex.Store(storeData);

Vue.mixin({
    methods: {
        formatDateString(dateobj) {
            return moment(dateobj).format('MM-DD-YYYY');
        },
		showLoader(){
			store.commit('saveLoaderState',true);
			return true;
		},
		hideLoader(){
			store.commit('saveLoaderState',false);
			return true;
		}
    }
});

router.beforeEach((to, from, next) => {
	const publicPages = ['/login','/register','/','/forgotpassword'];
	const authRequired = !publicPages.includes(to.path);
	const isLoggedIn = localStorage.getItem(store.getters.getLocalStorageName);
	if(to.path.startsWith("/resetpassword")){
		return next();
	}
	if (authRequired && !isLoggedIn) {
		console.log('redirecting');
		return next('/login');
	}
	if(isLoggedIn && !authRequired){
		console.log('secured');
		return next('/dashboard');
	}
	next();
});

window.axios.interceptors.request.use(config => {
	const ignoreList = ['/v1/client/lookup'];
	if(!ignoreList.some(substring=>config.url.includes(substring))){
		store.commit('saveLoaderState',true);
	}
  	return config;
});

window.axios.interceptors.response.use(function (response) {
	store.commit('saveLoaderState',false);
  	return response;
}, function (error) {
	store.commit('saveLoaderState',false);
	if(error.response.status==401){
		console.log('expired');
		store.commit("saveAccessToken",null);
		localStorage.removeItem(store.getters.getLocalStorageName);
		localStorage.removeItem(store.getters.getLocalStorageKeyName);
		router.push('login');
	}
  	return Promise.reject(error) // this is the important part
});

new Vue({
    router,
    store,
	vuetify,
    render: h => h(App),
}).$mount('#app');
