import moment from 'moment';

export default {
    toCurrency: (value) => {
        value = parseInt(value);
        if (typeof value !== "number") {
            return value;
        }
        var formatter = new Intl.NumberFormat('en-US', {
            style: 'currency',
            currency: 'USD',
            minimumFractionDigits: 2
        });
        return formatter.format(value);
    },
    formatDate: (value) => {
        if (value) {
            return moment(String(value)).format('MM-DD-YYYY hh:mm A');
        }
    },
    formatDateOnly: (value) => {
        if (value) {
            return moment(String(value)).format('MM-DD-YYYY');
        }
    },
    UCFirst: (value) => {
        return value.charAt(0).toUpperCase() + value.slice(1);
    }
}