import ChangePassword from '../components/UserProfile/ChangePassword';
import UserProfileUpdate from '../components/UserProfile/UserProfileUpdate';

export default [
    {
		path: '/profile/changepassword',
		name: 'changeuserpassword',
		component: ChangePassword,
		meta: {layout: 'dashboard'}
	},
    {
		path: '/profile',
		name: 'updateuserprofile',
		component: UserProfileUpdate,
		meta: {layout: 'dashboard'}
	}
];