<template>
    <ApplicationPreview :patientId="patientId" :applicationId="applicationId" @trigger-next="submitApplication()" @trigger-previous="goPrevious()" />
</template>

<script>
    import ApplicationPreview from "../CommonElements/ApplicationPreview";
    export default {
        components: {
            ApplicationPreview,
        },
        data(){
            return {
                patientId:this.$store.getters.getRecentTestApplicationPatientID,
                applicationId:this.$store.getters.getRecentTestApplicationID,
            }
        },
        mounted(){
            if(!this.$store.getters.isTestApplicationLoaded){
                this.$router.push("/test/profile");
            }
        },
        methods:{
            goPrevious(){
                this.$router.push("/test/application");
            },
            submitApplication(){
                if(confirm('Have you verified all the information before submitting? Are you sure about proceeding')){
                    axios.post(this.$store.getters.getAPIBasePath + '/applications/submit',{
                        patientId:this.patientId,
                        applicationId:this.applicationId,
                    })
                    .then(response => {
                        this.$awn.success(response.data.headers.message);
                        this.$router.push("/dashboard");
                    })
                    .catch(error => {
                        if(!error.response.data.headers.length){
                            this.$awn.alert(error.response.data.headers.message);
                        }
                    });
                }
            }
        }
    };
</script>