<template>
    <Questionnaire v-if="isLoaded==1" :answers="questionnaireData.answers" @trigger-previous="goPrevious()" @trigger-next="saveAnswer()" />
</template>

<script>
    import Questionnaire from "../CommonElements/Questionnaire";
    export default {
        components: {
            Questionnaire,
        },
        data(){
            return {
                isLoaded:0,
                questionnaireData : {
                    patientId:this.$store.getters.getRecentTestApplicationPatientID,
                    applicationId:this.$store.getters.getRecentTestApplicationID,
                    answers:{}
                }
            }
        },
        mounted(){
            if(!this.$store.getters.isTestApplicationLoaded){
                this.$router.push("/test/profile");
            }
            this.loadSavedAnswers();
        },
        methods:{
            loadSavedAnswers(){
                axios.get(this.$store.getters.getAPIBasePath + '/applications/view/'+this.questionnaireData.applicationId+'/viewanswers')
                .then(response => {
                    let apiResponse = response.data;
                    if(apiResponse.hasOwnProperty('recordinfo')){
                        this.questionnaireData.answers = apiResponse.recordinfo.answers;
                    }
                    this.isLoaded = 1;
                })
                .catch(error => {
                    console.log(error.response.data);
                });
            },
            saveAnswer(){
                axios.post(this.$store.getters.getAPIBasePath + '/applications/saveanswer',this.questionnaireData)
                .then(response => {
                    this.$router.push("/test/application");
                })
                .catch(error => {
                    console.log(error.response.data);
                });
            },
            goPrevious(){
                this.$router.push("/test/factsheet");
            }
        }
    };
</script>