<template>
    <section class="disclaimer">
        <div class="container">
            <h3>FDA Disclaimer</h3>
            <p>COVID-19 is caused by the SARS-CoV-2 virus. Infection with the virus can range from being asymptomatic to life-threatening respiratory illness. Infection has been detected globally and in all 50 states. Symptoms associated with COVID- 19 include cough, shortness of breath or difficulty breathing, fever, chills, muscle pain, headache, sore throat or new loss of taste or smell. COVID-19 can present with severe illness in individuals of any age and without any previous health problems, but the risk for severe illness from COVID-19 increases with age, with older adults at highest risk. Having underlying medical conditions may also increase one’s risk for severe illness from COVID-19. Conditions and other risk factors that may be associated with severe illness and death are listed below. If you have any symptoms concerning for COVID-19 and any of the following conditions or risk factors, which may put you at increased risk of severe illness from COVID-19, you should consult with your healthcare provider before using this test:</p>
            <ul>
                <li>Chronic kidney disease</li>
                <li>COPD (chronic obstructive pulmonary disease)</li>
                <li>Immunocompromised state (weakened immune system) from solid organ transplant or bone marrow transplant, immune deficiencies, HIV, use of corticosteroids, or use of other immune weakening medicines</li>
                <li>Obesity (body mass index [BMI] of 30 or higher)</li>
                <li>Serious heart conditions, such as heart failure, coronary artery disease, or cardiomyopathies</li>
                <li>Sickle cell disease</li>
                <li>Type 2 diabetes mellitus</li>
                <li>Asthma (moderate-to-severe)</li>
                <li>Cerebrovascular disease (affects blood vessels and blood supply to the brain)</li>
                <li>Cystic fibrosis</li>
                <li>Hypertension or high blood pressure</li>
                <li>Neurologic conditions, such as dementia</li>
                <li>Liver disease</li>
                <li>Pregnancy</li>
                <li>Pulmonary fibrosis (having damaged or scarred lung tissues)</li>
                <li>Smoking</li>
                <li>Thalassemia (a type of blood disorder)</li>
            </ul>
            <h4>Type 1 diabetes mellitus</h4>
            <p>Children who are medically complex, who have neurologic, genetic, metabolic conditions, or who have congenital heart disease</p>
            <p>Regardless of your risk status, if you are experiencing any of the following emergency warning signs for COVID-19, it is recommended that you immediately seek emergency care:</p>
            <ul>
                <li>Trouble breathing</li>
                <li>Persistent pain or pressure in the chest</li>
                <li>New confusion</li>
                <li>Inability to wake up or stay awake</li>
                <li>Bluish lips or face</li>
            </ul>
            <div class="text-center mb-5">
                <button type="button" @click="triggerPreviousAction()" class="bttn bttn--secondary mx-2">Back</button>
                <button type="button" @click="triggerNextAction()" class="bttn bttn--primary mx-2">Next</button>
            </div>
        </div>
    </section>
</template>

<script>
    export default {
        methods:{
            triggerNextAction(){
                this.$emit('trigger-next');
            },
            triggerPreviousAction(){
                this.$emit('trigger-previous');
            }
        }
    };
</script>
