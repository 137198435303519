<template>
    <v-app>
        <div v-if="showLoader" class="loader-bg active">
            <v-progress-circular indeterminate color="primary"></v-progress-circular>
        </div>
        <slot/>
    </v-app>
</template>
<script>
export default {
    computed: {
        showLoader() {
            return this.$store.getters.getLoader;
        }
    }
}
</script>