<template>
    <div>
        <div class="login-container">
            <div class="container ">
                <div class="login-container__inner">
                    <div class="row justify-content-center">
                        <div class="col-md-6 login-feature-image d-none d-lg-block">
                            <img src="../../assets/images/login-dummy.jpeg" alt="">
                        </div>
                        <div class="col-lg-6 col-md-9 login-form">
                            <div class="login-header">
                                <h3>Login</h3>
                                <img src="../../assets/images/logo.png" width="200px" alt="">
                            </div>
                            <form @submit.prevent="performLogin()" id="login-form">
                                <div class="form-group">
                                    <label class="form__label" for="email">Email</label>
                                    <input v-model="login.email" type="text" id="email" class="form-control" placeholder="Your Email *"/>
                                    <div v-if="loginerrors.email != undefined" class="mt-1 mb-2 vuelidate-error">{{ loginerrors.email }}</div>
                                    <div class="mt-1 mb-2 vuelidate-error" v-if="$v.login.email.$error && !$v.login.email.required">Email is required.</div>
                                    <div class="mt-1 mb-2 vuelidate-error" v-if="$v.login.email.$error && !$v.login.email.maxLength">Email must have at most {{ $v.login.email.$params.maxLength.max }} letters.</div>
                                    <div class="mt-1 mb-2 vuelidate-error" v-if="$v.login.email.$error && !$v.login.email.email">Please enter valid email</div>
                                </div>
                                <div class="form-group">
                                    <label class="form__label" for="password">Password</label>
                                    <input v-model="login.password" type="password" id="password" class="form-control" placeholder="Your Password *"/>
                                    <div v-if="loginerrors.password != undefined" class="mt-1 mb-2 vuelidate-error">{{ loginerrors.password }}</div>
                                    <div class="mt-1 mb-2 vuelidate-error" v-if="$v.login.password.$error && !$v.login.password.required">Password is required.</div>
                                    <div class="mt-1 mb-2 vuelidate-error" v-if="$v.login.password.$error && !$v.login.password.minLength">Password must have at least {{ $v.login.password.$params.minLength.min }} letters.</div>
                                    <div class="mt-1 mb-2 vuelidate-error" v-if="$v.login.password.$error && !$v.login.password.maxLength">Password cannot exceed {{ $v.login.password.$params.maxLength.max }} letters.</div>
                                </div>
                                <div class="text-center mb-5">
                                    <button type="submit" class="bttn bttn--primary mx-2">Login</button>
                                </div>
                                <div class="form-group">
                                    <router-link class="ForgetPwd float-left" :to="{ name: 'forgotpassword' }">Forgot Password?</router-link>
                                    <router-link class="ForgetPwd float-right" :to="{ name: 'register' }">Click here to Register</router-link>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import { encryptData } from '../../helpers/AuthUtils';
import { required, minLength, maxLength, email } from 'vuelidate/lib/validators';
export default {
    data() {
        return {
            login: {
                email: '',
                password: ''
            },
            loginerrors: {}
        }
    },
    validations: { 
        login: {
            email: {
                required,
                email,
                maxLength: maxLength(250) 
            },
            password: {
                required,
                minLength: minLength(6),
                maxLength: maxLength(20)
            }
        }
    },
    mounted() {},
    methods: {
        performLogin() {
            this.loginerrors = {};
            this.$v.login.$touch();
            if (!this.$v.login.$invalid) {
                axios.post(this.$store.getters.getAPIBasePath + '/auth/login', this.login)
                .then(response => {
                    let authHeaders = response.data.authenticationHeaders;
                    let profileData = response.data.profileData;
                    this.$store.commit('saveAccessToken',authHeaders.accesstoken);
                    localStorage.setItem(this.$store.getters.getLocalStorageName,encryptData(authHeaders.accesstoken));
                    localStorage.setItem(this.$store.getters.getLocalStorageKeyName,encryptData(authHeaders.refreshkey));
                    this.$awn.success("Login successful. Welcome "+profileData.firstName+" "+profileData.lastName);
                    this.$router.push('/dashboard');
                })
                .catch(error => {
                    if(!error.response.data.headers.length){
                        this.$awn.alert(error.response.data.headers.message);
                    }
                    if(!error.response.data.errorlist.length){
                        this.loginerrors = error.response.data.errorlist;
                    }
                });
            }
            else{
                console.log('show error');
            }
        }
    }
}

</script>
