<template>
    <section>
        <div class="container">
            <div class="text-center form-header">
                <h3 class="form-heading">Activate Kit</h3>
                <p>Please enter the 14-digit barcode located on your collection tube to activate your kit.</p>
            </div>
            <img src="../../assets/images/register-barcode-diagram.svg" alt="Barcode Diagram" class="barcode-img" />
            <div class="messages"></div>
            <div class="row justify-content-center">
                <div class="col-md-6 col-sm-6">
                    <div class="form-group">
                        <label for="barcode_id">Enter Barcode #</label>
                        <input id="barcode_id" type="text" name="barcode_id" v-model="kitVerification.testKitNo" class="form-control" placeholder="Please enter your Barcode #">
                        <div v-if="verificationErrors.testKitNo != undefined" class="mt-1 mb-2 vuelidate-error">{{ verificationErrors.testKitNo }}</div>
                    </div>
                </div>
                <div class="col-md-6 col-sm-6">
                    <div class="form-group">
                        <label for="confirm_barcode_id">Confirm Barcode #</label>
                        <input id="confirm_barcode_id" type="text" v-model="kitVerification.confirmTestKitNo" name="confirm_barcode_id" class="form-control" placeholder="Please confirm your Barcode #">
                        <div v-if="verificationErrors.confirmTestKitNo != undefined" class="mt-1 mb-2 vuelidate-error">{{ verificationErrors.confirmTestKitNo }}</div>
                    </div>
                </div>
                <div class="col-md-6 col-sm-6" v-if="isKitVerified==1">
                    <div class="form-group">
                        <label for="form_name">Select Patient *</label>
                        <select class="form-control custom-select" v-model="currentPatient" name="patientProfile" id="patientProfile">
                            <option v-for="(data) in patientProfiles" :key="data.id" :value="data.id">{{data.firstName}} {{data.lastName}}</option>
                        </select>
                        <div class="help-block with-errors"></div>
                    </div>
                </div>
            </div>
            <div class="login-submit mt-2">
                <input v-if="isKitVerified==0" type="button" @click="verifyTestKit" class="bttn bttn--primary" value="Verify" />
                <input v-if="isKitVerified==1" type="button" @click="activateTestKit" class="bttn bttn--primary" value="Activate" />
            </div>
        </div>
    </section>
</template>

<script>
    export default {
        data() {
            return {
                currentPatient:'',
			    patientProfiles:[],
                isKitVerified:0,
                kitVerification:{
                    testKitNo:'',
                    confirmTestKitNo:''
                },
                verificationErrors:{},
                activationErrors:{}
            }
        },
        mounted(){
            this.loadPatientProfiles();
        },
        methods:{
            verifyTestKit(){
                this.verificationErrors = {};
                axios.post(this.$store.getters.getAPIBasePath + '/testkit/verify',this.kitVerification)
                .then(response => {
                    this.$awn.success("Barcode# is available. Please select the Patient profile to Activate");
                    this.isKitVerified = 1;
                })
                .catch(error => {
                    if(!error.response.data.headers.length){
                        this.$awn.alert(error.response.data.headers.message);
                    }
                    if(!error.response.data.errorlist.length){
                        this.verificationErrors = error.response.data.errorlist;
                        console.log(this.verificationErrors);
                    }
                });
            },
            activateTestKit(){
                if(confirm('Are you sure about Activating this kit for this Patient? Once the Kit is assigned it cannot be re-used for other Patients')){
                    this.activationErrors = {};
                    axios.post(this.$store.getters.getAPIBasePath + '/testkit/activate',{
                        patientId:this.currentPatient,
                        testKitNo:this.kitVerification.testKitNo
                    })
                    .then(response => {
                        let appInfo = response.data.applicationInfo;
                        console.log(appInfo);
                        this.$store.commit("saveRecentActivationApplicationID",appInfo.id);
                        this.$store.commit("saveRecentActivationApplicationPatientID",appInfo.patientId);
                        this.$store.commit("saveRecentActivationApplicationStatus",appInfo.applicationStatus);
                        if(appInfo.applicationStatus=='pending'){
                            this.$router.push('/testkit/application/preview');
                        }
                        else{
                            this.$router.push('/testkit/fdadisclaimer');
                        }
                    })
                    .catch(error => {
                        if(!error.response.data.headers.length){
                            this.$awn.alert(error.response.data.headers.message);
                        }
                        if(!error.response.data.errorlist.length){
                            this.verificationErrors = error.response.data.errorlist;
                            console.log(this.verificationErrors);
                        }
                    });
                }
            },
            loadPatientProfiles() {
                axios.get(this.$store.getters.getAPIBasePath + '/patientprofiles')
                .then(response => {
                    let profiles = response.data.data;
                    this.patientProfiles = profiles;
                    if(profiles.length>0){
                        this.currentPatient = profiles[0].id;
                    }
                })
                .catch(error => {
                    console.log(error);
                });
            },
        }
    };
</script>
