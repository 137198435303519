import TestProfileSelection from '../components/TestApplication/TestProfileSelection';
import TestFDADisclaimer from '../components/TestApplication/TestFDADisclaimer';
import TestFactSheet from '../components/TestApplication/TestFactSheet';
import TestQuestionnaire from '../components/TestApplication/TestQuestionnaire';
import TestApplication from '../components/TestApplication/TestApplication';
import TestApplicationPreview from '../components/TestApplication/TestApplicationPreview';

export default [
    {
		path: '/test/profile',
		name: 'test_patientselection',
		component: TestProfileSelection,
		meta: { layout: 'dashboard'}
	},
    {
		path: '/test/fdadisclaimer',
		name: 'test_fdadisclaimer',
		component: TestFDADisclaimer,
		meta: { layout: 'dashboard'}
	},
    {
		path: '/test/factsheet',
		name: 'test_factsheet',
		component: TestFactSheet,
		meta: { layout: 'dashboard'}
	},
    {
		path: '/test/questions',
		name: 'test_questions',
		component: TestQuestionnaire,
		meta: { layout: 'dashboard'}
	},
	{
		path: '/test/application',
		name: 'test_application',
		component: TestApplication,
		meta: { layout: 'dashboard'}
	},
	{
		path: '/test/application/preview',
		name: 'test_application_preview',
		component: TestApplicationPreview,
		meta: { layout: 'dashboard'}
	}
];
