<template>
    <v-app>
        <div v-if="pageReady==1">
            <header class="py-3">
                <div class="container-fluid">
                    <div class="d-flex justify-content-between align-items-center">
                        <router-link class="navbar-brand" :to="{ name: 'dashboard' }">
                            <img src="../../assets/images/logo.png" class="img-fluid" alt="img" width="250px">
                        </router-link>
                        <div class="dropdown">
                            <button class="btn btn-clear dropdown-toggle" type="button" id="dropdownUserMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                Welcome {{profileData.firstName}} {{profileData.lastName}}
                            </button>
                            <div class="dropdown-menu dropdown-menu-right" aria-labelledby="dropdownUserMenuButton">
                                <router-link class="dropdown-item" :to="{ name: 'updateuserprofile' }">My Profile</router-link>
                                <router-link class="dropdown-item" :to="{ name: 'changeuserpassword' }">Change Password</router-link>
                                <a href="Javascript:void(0)" @click="doLogout()" class="dropdown-item">Logout</a>
                            </div>
                        </div>
                    </div>
                    
                </div>
            </header>
            <section class="reg-nav">
                    <ul>
                        <li class="active">
                            <router-link :to="{ name: 'dashboard' }">
                                <div>
                                    <!-- <svg viewBox="0 0 200 200" xmlns="http://www.w3.org/2000/svg" height="30px" width="30px">
                                        <rect width="100" height="100" x="50" y="50" />
                                    </svg> -->
                                    <svg version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" height="30px" width="30px" x="0px" y="0px"
                                    viewBox="0 0 384 384" style="enable-background:new 0 0 384 384;" xml:space="preserve">
                                        <g>
                                            <g>
                                                <g>
                                                    <rect x="213.333" y="0" width="170.667" height="128"/>
                                                    <rect x="0" y="0" width="170.667" height="213.333"/>
                                                    <rect x="0" y="256" width="170.667" height="128"/>
                                                    <rect x="213.333" y="170.667" width="170.667" height="213.333"/>
                                                </g>
                                            </g>
                                        </g>
                                    </svg>
                                </div>
                                <p>Dashboard</p>
                            </router-link>
                        </li>
                        <li class="active">
                            <router-link :to="{ name: 'test_patientselection' }">
                                <div>
                                    <!-- <svg viewBox="0 0 200 200" xmlns="http://www.w3.org/2000/svg" height="30px" width="30px">
                                        <rect width="100" height="100" x="50" y="50" />
                                    </svg> -->
                                    <svg version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg" height="30px" width="30px" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
                                            viewBox="0 0 512 512" style="enable-background:new 0 0 512 512;" xml:space="preserve">
                                        <g>
                                            <g>
                                                <path d="M215.069,457.93C213.21,456.069,210.63,455,208,455s-5.21,1.069-7.07,2.93c-1.86,1.861-2.93,4.44-2.93,7.07
                                                    s1.069,5.21,2.93,7.069c1.86,1.86,4.44,2.931,7.07,2.931s5.21-1.07,7.069-2.931C216.93,470.21,218,467.63,218,465
                                                    S216.93,459.79,215.069,457.93z"/>
                                            </g>
                                        </g>
                                        <g>
                                            <g>
                                                <path d="M349.385,27.897h-62.77c-5.522,0-10,4.477-10,10c0,5.523,4.478,10,10,10h62.77c5.522,0,10-4.477,10-10
                                                    C359.385,32.374,354.907,27.897,349.385,27.897z"/>
                                            </g>
                                        </g>
                                        <g>
                                            <g>
                                                <path d="M454,226c5.522,0,10-4.477,10-10V36.154C464,16.218,447.781,0,427.846,0H208.154C188.219,0,172,16.218,172,36.154v69.05
                                                    c-28.865,23.407-52.662,53.255-68.974,86.634c-17.035,34.859-26.039,73.793-26.039,112.592V409H68c-11.028,0-20,8.972-20,20v73
                                                    c0,5.522,4.478,10,10,10s10-4.478,10-10v-73h177.432v73c0,5.522,4.478,10,10,10c5.522,0,10-4.478,10-10v-44h162.414
                                                    C447.781,458,464,441.781,464,421.846V299.129c0-5.522-4.478-10-10-10c-5.522,0-10,4.478-10,10V373H294.074l31.803-44.814
                                                    c7.586-10.689,8.562-24.563,2.546-36.209s-17.895-18.881-31.003-18.881c-7.459,0-14.862,2.454-20.847,6.911l-63.158,47.052
                                                    c-2.298,1.712-5.143,2.655-8.009,2.655c-7.393,0-13.406-6.014-13.406-13.406V304V76.042h252V216C444,221.523,448.478,226,454,226z
                                                    M444,393v28.846h0c0,8.907-7.247,16.154-16.154,16.154H265.432v-9c0-6.565-3.194-12.384-8.094-16.032
                                                    c8.327-5.44,15.855-12.157,22.264-19.988c0.133,0.005,0.264,0.02,0.398,0.02H444z M205.406,349.714
                                                    c7.143,0,14.23-2.35,19.958-6.616l63.157-47.052c2.555-1.902,5.715-2.95,8.898-2.95c5.68,0,10.627,3.013,13.233,8.06
                                                    c2.606,5.046,2.2,10.823-1.087,15.455l-42.199,59.464C252.735,396.691,228.907,409,203.627,409H96.987V304.43
                                                    c0-66.307,27.1-128.175,75.013-172.776V304v12.308C172,334.728,186.986,349.714,205.406,349.714z M192,56.042V36.154
                                                    C192,27.247,199.247,20,208.154,20h219.691C436.753,20,444,27.247,444,36.154v19.888H192z"/>
                                            </g>
                                        </g>
                                        <g>
                                            <g>
                                                <path d="M325.069,407.58c-1.859-1.86-4.439-2.931-7.069-2.931s-5.21,1.07-7.07,2.931c-1.86,1.859-2.93,4.439-2.93,7.069
                                                    c0,2.641,1.069,5.21,2.93,7.07c1.86,1.871,4.44,2.93,7.07,2.93s5.21-1.06,7.069-2.93c1.86-1.86,2.931-4.43,2.931-7.07
                                                    C328,412.019,326.93,409.439,325.069,407.58z"/>
                                            </g>
                                        </g>
                                        <g>
                                            <g>
                                                <path d="M393.365,158.787c-4.206-5.16-10.437-8.12-17.094-8.12h-27.325L340.479,136c-2.761-4.783-8.877-6.421-13.66-3.66
                                                    c-4.783,2.761-6.422,8.877-3.66,13.66l2.694,4.667h-15.705l2.694-4.667c2.762-4.783,1.123-10.899-3.66-13.66
                                                    c-4.785-2.762-10.897-1.123-13.66,3.66l-8.468,14.667h-27.325c-6.657,0-12.888,2.959-17.094,8.12s-5.849,11.859-4.507,18.38
                                                    l13.155,63.896c2.102,10.201,11.186,17.604,21.6,17.604h90.234c10.414,0,19.498-7.403,21.601-17.605l13.154-63.895
                                                    C399.214,170.646,397.571,163.947,393.365,158.787z M378.283,173.134l-13.153,63.893c-0.196,0.951-1.042,1.641-2.012,1.641
                                                    h-90.234c-0.97,0-1.815-0.69-2.011-1.639l-13.154-63.894c-0.163-0.79,0.139-1.366,0.419-1.711
                                                    c0.281-0.345,0.786-0.756,1.592-0.756h15.778l-5.677,9.833c-2.762,4.783-1.123,10.899,3.66,13.66
                                                    c1.575,0.909,3.294,1.341,4.99,1.341c3.456,0,6.817-1.793,8.67-5.001l11.451-19.833H337.4v-0.001l11.451,19.833
                                                    c1.853,3.208,5.213,5.001,8.67,5.001c1.696,0,3.416-0.432,4.99-1.341c4.783-2.761,6.422-8.877,3.66-13.66l-5.677-9.833h15.778
                                                    c0.806,0,1.311,0.411,1.592,0.756C378.144,171.768,378.446,172.345,378.283,173.134z"/>
                                            </g>
                                        </g>
                                        <g>
                                            <g>
                                                <path d="M461.069,248.93C459.21,247.07,456.63,246,454,246s-5.21,1.07-7.07,2.93c-1.86,1.86-2.93,4.44-2.93,7.07
                                                    s1.069,5.21,2.93,7.069c1.86,1.86,4.44,2.931,7.07,2.931s5.21-1.07,7.069-2.931C462.93,261.21,464,258.63,464,256
                                                    S462.93,250.79,461.069,248.93z"/>
                                            </g>
                                        </g>
                                    </svg>
                                </div>
                                <p>Order a test</p>
                            </router-link>
                        </li>
                        <li class="active">
                            <router-link :to="{ name: 'kitactivation' }">
                                <div>
                                    <!-- <svg viewBox="0 0 200 200" xmlns="http://www.w3.org/2000/svg" height="30px" width="30px">
                                        <rect width="100" height="100" x="50" y="50" />
                                    </svg> -->
                                    <svg id="Capa_1" enable-background="new 0 0 512 512" height="30px" viewBox="0 0 512 512" width="30px" xmlns="http://www.w3.org/2000/svg"><g><path d="m256 0c-141.159 0-256 114.841-256 256s114.841 256 256 256 256-114.841 256-256-114.841-256-256-256zm-15 86c0-8.284 6.716-15 15-15s15 6.716 15 15v120c0 8.284-6.716 15-15 15s-15-6.716-15-15zm15 335c-90.981 0-165-74.019-165-165 0-65.544 38.856-124.886 98.991-151.179 7.59-3.32 16.434.144 19.753 7.734s-.144 16.434-7.734 19.753c-49.212 21.518-81.01 70.069-81.01 123.692 0 74.439 60.561 135 135 135s135-60.561 135-135c0-53.623-31.798-102.174-81.009-123.691-7.59-3.319-11.053-12.163-7.734-19.753 3.318-7.591 12.162-11.056 19.753-7.734 60.134 26.292 98.99 85.634 98.99 151.178 0 90.981-74.019 165-165 165z"/></g></svg>
                                </div>
                                <p>Activate Test Kit</p>
                            </router-link>
                        </li>
                    </ul>
            </section>
            <slot/>
            <footer>
                <div class="container-fluid">
                    <div class="footer-inner">
                        <p>© 2021 Viral Vigilance</p>
                        <ul class="footer-links">
                            <li><a href="https://viralvigilance.com/our-team">Board of Directors</a></li>
                            <li><a href="https://viralvigilance.com/telmed">Telmed</a></li>
                            <li><a href="https://viralvigilance.com/company-1">Company</a></li>
                            <li><a href="https://viralvigilance.com/regulatory-docs">Regulatory Docs</a></li>
                        </ul>
                    </div>
                </div>
            </footer>
        </div>
        <div v-if="showLoader" class="loader-bg active">
            <v-progress-circular indeterminate color="primary"></v-progress-circular>
        </div>
    </v-app>
</template>
<script>
import { decryptData } from '../../helpers/AuthUtils';
import { isUserAuthenticated, getUserData} from '../../helpers/AuthUtils'
export default {
    data(){
        return {
            pageReady:0,
            profileData:{}
        }
    },
    computed: {
        showLoader() {
            return this.$store.getters.getLoader;
        }
    },
    mounted(){
        let accessToken = this.$store.getters.getAccessToken;
        if(accessToken==null){
            let savedToken = localStorage.getItem(this.$store.getters.getLocalStorageName);
            if(!savedToken){
                this.$router.push('login');
            }
            accessToken = decryptData(savedToken);
        }
        window.axios.defaults.headers.common['Content-Type'] = 'application/json';
        window.axios.defaults.headers.common['Authorization'] = "Bearer "+accessToken;
        this.loadUserData();
    },
    methods: {
        doLogout(){
            this.$store.commit("saveAccessToken",null);
            let savedKey = localStorage.getItem(this.$store.getters.getLocalStorageKeyName);
            let decodedKey = decryptData(savedKey)
            localStorage.removeItem(this.$store.getters.getLocalStorageName);
            localStorage.removeItem(this.$store.getters.getLocalStorageKeyName);
            axios.post(this.$store.getters.getAPIBasePath + '/auth/logout',{refreshKey:decodedKey})
            .then(response => {
                console.log(response.data);
            })
            .catch(error => {
                
            });
            this.$awn.success("Your account logged out successfully");
            this.$router.push('login');
        },
        loadUserData(){
            axios.get(this.$store.getters.getAPIBasePath + '/profile')
            .then(response => {
                this.$store.commit('saveUserData',response.data.recordinfo);
                this.profileData = response.data.recordinfo;
                this.loadMasterData();
            })
            .catch(error => {
                console.log(error.response.data);
            });
        },
        loadMasterData(){
            this.loadAccountHolderTypes();
            this.loadCommonOptions();
            this.loadTestTypes();
            this.loadPregnancyStates();
            this.loadEthnicities();
            this.loadRaces();
            this.loadPatientRelations();
            this.loadGenders();
        },
        loadAccountHolderTypes() {
            axios.get(this.$store.getters.getAPIBasePath + '/lookup/account_holder_type')
            .then(response => {
                this.$store.commit('saveAccountHolderTypes',response.data.data);
            })
            .catch(error => {
                console.log(error.response);
            });
        },
        loadCommonOptions() {
            axios.get(this.$store.getters.getAPIBasePath + '/lookup/common_options')
            .then(response => {
                this.$store.commit('saveCommonOptions',response.data.data);
            })
            .catch(error => {
                console.log(error.response);
            });
        },
        loadTestTypes() {
            axios.get(this.$store.getters.getAPIBasePath + '/lookup/test_type')
            .then(response => {
                this.$store.commit('saveTestTypes',response.data.data);
            })
            .catch(error => {
                console.log(error.response);
            });
        },
        loadPregnancyStates() {
            axios.get(this.$store.getters.getAPIBasePath + '/lookup/pregnancy_status')
            .then(response => {
                this.$store.commit('savePregnancyStates',response.data.data);
            })
            .catch(error => {
                console.log(error.response);
            });
        },
        loadEthnicities() {
            axios.get(this.$store.getters.getAPIBasePath + '/lookup/ethnicity')
            .then(response => {
                this.$store.commit('saveEthnicities',response.data.data);
            })
            .catch(error => {
                console.log(error.response);
            });
        },
        loadRaces() {
            axios.get(this.$store.getters.getAPIBasePath + '/lookup/race')
            .then(response => {
                this.$store.commit('saveRaces',response.data.data);
            })
            .catch(error => {
                console.log(error.response);
            });
        },
        loadPatientRelations() {
            axios.get(this.$store.getters.getAPIBasePath + '/lookup/patient_relation')
            .then(response => {
                this.$store.commit('savePatientRelations',response.data.data);
            })
            .catch(error => {
                console.log(error.response);
            });
        },
        loadGenders() {
            axios.get(this.$store.getters.getAPIBasePath + '/lookup/gender')
            .then(response => {
                this.$store.commit('saveGenders',response.data.data);
                this.setPageReady();
            })
            .catch(error => {
                console.log(error.response);
            });
        },
        setPageReady(){
            this.pageReady = 1;
        }
    }
}
</script>
