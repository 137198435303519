export default {
    state: {
        showLoader:false,
        userdata: {},
		apibasepath: process.env.VUE_APP_BASE_URL,
		accesstoken:null,
        localStorageName: process.env.VUE_APP_LOCAL_STORAGE_NAME,
        localStorageKeyName: 'userKey',
        accountHolderTypes:[],
        commonOptions:[],
        testTypes:[],
        pregnancyStates:[],
        ethnicities:[],
        races:[],
        patientRelations:[],
        genders:[],
        testApplicationID:null,
        testApplicationPatientID:null,
        activationApplicationID:null,
        activationApplicationPatientID:null,
        activationApplicationStatus:null,
    },
    getters: {
        getLoader(state){
            return state.showLoader;
        },
        getUserData(state) {
            return state.userdata;
        },
        getAPIBasePath(state) {
            return state.apibasepath;
        },
        getLocalStorageName(state){
            return state.localStorageName;
        },
        getLocalStorageKeyName(state){
            return state.localStorageKeyName;
        },
        getAccessToken(state){
            return state.accesstoken;
        },
        getAccountHolderTypes(state){
            return state.accountHolderTypes;
        },
        getCommonOptions(state){
            return state.commonOptions;
        },
        getTestTypes(state){
            return state.testTypes;
        },
        getPregnancyStates(state){
            return state.pregnancyStates;
        },
        getEthnicities(state){
            return state.ethnicities;
        },
        getRaces(state){
            return state.races;
        },
        getPatientRelations(state){
            return state.patientRelations;
        },
        getGenders(state){
            return state.genders;
        },
        getRecentTestApplicationID(state){
            return state.testApplicationID;
        },
        getRecentTestApplicationPatientID(state){
            return state.testApplicationPatientID;
        },
        isTestApplicationLoaded(state){
            return (state.testApplicationID!=null && state.testApplicationPatientID!=null)?true:false;
        },
        getRecentActivationApplicationID(state){
            return state.activationApplicationID;
        },
        getRecentActivationApplicationPatientID(state){
            return state.activationApplicationPatientID;
        },
        getRecentActivationApplicationStatus(state){
            return state.activationApplicationStatus;
        },
        isActivationApplicationLoaded(state){
            return (state.activationApplicationID!=null && state.activationApplicationPatientID!=null)?true:false;
        }
    },
    mutations: {
        saveLoaderState(state, payload) {
            state.showLoader = payload;
        },
        saveUserData(state, payload) {
            state.userdata = payload;
        },
        saveAccessToken(state, payload){
            state.accesstoken = payload;
        },
        saveAccountHolderTypes(state, payload){
            state.accountHolderTypes = payload;
        },
        saveCommonOptions(state, payload){
            state.commonOptions = payload;
        },
        saveTestTypes(state, payload){
            state.testTypes = payload;
        },
        savePregnancyStates(state, payload){
            state.pregnancyStates = payload;
        },
        saveEthnicities(state, payload){
            state.ethnicities = payload;
        },
        saveRaces(state, payload){
            state.races = payload;
        },
        savePatientRelations(state, payload){
            state.patientRelations = payload;
        },
        saveGenders(state, payload){
            state.genders = payload;
        },
        saveRecentTestApplicationID(state, payload){
            state.testApplicationID = payload;
        },
        saveRecentTestApplicationPatientID(state, payload){
            state.testApplicationPatientID = payload;
        },
        saveRecentActivationApplicationID(state, payload){
            state.activationApplicationID = payload;
        },
        saveRecentActivationApplicationPatientID(state, payload){
            state.activationApplicationPatientID = payload;
        },
        saveRecentActivationApplicationStatus(state, payload){
            state.activationApplicationStatus = payload;
        }
    },
    actions: {
    }
}
