<template>
    <section class="application">
        <div class="container">
            <div class="question">
                <div class="question__header">
                    <h4>Patient Information</h4>
                </div>
                <div class="question__body">
                    <div class="row patient-info">
                        <div class="col-md-4 col-sm-6 patient-info__container">
                            <h6>First Name</h6>
                            <p>{{patientProfile.firstName}}</p>
                        </div>
                        <div class="col-md-4 col-sm-6 patient-info__container">
                            <h6>Middle Name </h6>
                            <p>{{patientProfile.middleName}}</p>
                        </div>
                        <div class="col-md-4 col-sm-6 patient-info__container">
                            <h6>Last Name</h6>
                            <p>{{patientProfile.lastName}}</p>
                        </div>
                        <div class="col-md-4 col-sm-6 patient-info__container">
                            <h6>Email</h6>
                            <p>{{patientProfile.email}}</p>
                        </div>
                        <div class="col-md-4 col-sm-6 patient-info__container">
                            <h6>Phone</h6>
                            <p>{{patientProfile.phoneNo}}</p>
                        </div>
                        <div class="col-md-4 col-sm-6 patient-info__container">
                            <h6>Address</h6>
                            <p>{{patientProfile.address}}, {{patientProfile.city}}, {{patientProfile.state}}-{{patientProfile.zipCode}} </p>
                        </div>
                        <div class="col-md-4 col-sm-6 patient-info__container">
                            <h6>Gender</h6>
                            <p>{{patientProfile.gender}}</p>
                        </div>
                        <div class="col-md-4 col-sm-6 patient-info__container">
                            <h6>Patient Relation</h6>
                            <p>{{patientProfile.relationToPatient}}</p>
                        </div>
                        <div class="col-md-4 col-sm-6" v-if="patientProfile.gender!='Male'">
                            <div class="form-group">
                                <label for="form_name">Pregnancy Status *</label>
                                <select v-model="applicationData.pregnancyStatus" class="form-control custom-select" name="gender" id="gender">
                                    <option value="">Select</option>
                                    <option v-for="(data) in pregnancyStates" :key="data.key" :value="data.key">{{data.label}}</option>
                                </select>
                                <div v-if="validationErrors.pregnancyStatus != undefined" class="mt-1 mb-2 vuelidate-error">{{ validationErrors.pregnancyStatus }}</div>
                            </div>
                        </div>
                        <div class="col-md-4 col-sm-6">
                            <div class="form-group">
                                <label for="form_name">Test Type *</label>
                                <select v-model="applicationData.testType" class="form-control custom-select" name="gender" id="gender">
                                    <option value="">Select</option>
                                    <option v-for="(data) in testTypes" :key="data.key" :value="data.key">{{data.label}}</option>
                                </select>
                                <div v-if="validationErrors.testType != undefined" class="mt-1 mb-2 vuelidate-error">{{ validationErrors.testType }}</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="question">
                <div class="question__header">
                    <h4>Medical Information</h4>
                </div>
                <div class="question__body">
                    <div class="row">
                        <div class="col-md-12 col-sm-6">
                            <div class="form-group">
                                <div class="form-check-inline">
                                    <label class="form-check-label">
                                        <input v-model="applicationData.proofType" type="radio" value="insurance" class="form-check-input" name="optradio">Insurance
                                    </label>
                                </div>
                                <div class="form-check-inline">
                                    <label class="form-check-label">
                                        <input v-model="applicationData.proofType" type="radio" value="other" class="form-check-input" name="optradio">I do not have medical insurance
                                    </label>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div v-if="applicationData.proofType === 'other'" class="row">
                        <div class="col-md-4 col-sm-6">
                            <div class="form-group">
                                <label for="ssn">SSN *</label>
                                <input-mask class="form-control" v-model="applicationData.ssnNo" mask="9999999999" placeholder="Please enter SSN Number" maskChar=""></input-mask>
                                <div v-if="validationErrors.ssnNo != undefined" class="mt-1 mb-2 vuelidate-error">{{ validationErrors.ssnNo }}</div>
                            </div>
                        </div>
                        <div class="col-md-4 col-sm-6">
                            <div class="form-group">
                                <label for="licenseNo">Driver license Number/state Id Number</label>
                                <input v-model="applicationData.drivingLicenseNo" maxlength="20" id="licenseNo" type="text" name="licenseNo" class="form-control" placeholder="Please enter Driver license Number / state Id Number *">
                                <div class="help-block with-errors"></div>
                            </div>
                        </div>
                    </div>
                    <div v-if="applicationData.proofType === 'insurance'" class="row">
                        <div class="col-md-4 col-sm-6">
                            <div class="form-group">
                                <label for="insurance_company">Insurance company *</label>
                                <input v-model="applicationData.insuranceName" id="insurance_company" type="text" name="insurance_company" class="form-control" placeholder="Please enter Insurance company">
                                <div v-if="validationErrors.insuranceName != undefined" class="mt-1 mb-2 vuelidate-error">{{ validationErrors.insuranceName }}</div>
                            </div>
                        </div>
                        <div class="col-md-4 col-sm-6">
                            <div class="form-group">
                                <label for="insurance_phoneno">Insurance Phone No *</label>
                                <input-mask class="form-control" v-model="applicationData.insurancePhoneNo" mask="(999)-999-9999" placeholder="Please enter Insurance Phone No" maskChar="_"></input-mask>
                                <div v-if="validationErrors.insurancePhoneNo != undefined" class="mt-1 mb-2 vuelidate-error">{{ validationErrors.insurancePhoneNo }}</div>
                            </div>
                        </div>
                        <div class="col-md-4 col-sm-6">
                            <div class="form-group">
                                <label for="member_id">Member No *</label>
                                <input v-model="applicationData.insuranceMemberNo" id="member_id" type="text" name="member_id" class="form-control" placeholder="Please enter Member id">
                                <div v-if="validationErrors.insuranceMemberNo != undefined" class="mt-1 mb-2 vuelidate-error">{{ validationErrors.insuranceMemberNo }}</div>
                            </div>
                        </div>
                        <div class="col-md-4 col-sm-6">
                            <div class="form-group">
                                <label for="group_number">Group No *</label>
                                <input v-model="applicationData.insuranceGroupNo" id="group_number" type="text" name="group_number" class="form-control" placeholder="Please enter Group number">
                                <div v-if="validationErrors.insuranceGroupNo != undefined" class="mt-1 mb-2 vuelidate-error">{{ validationErrors.insuranceGroupNo }}</div>
                            </div>
                        </div>
                        <div class="col-md-4 col-sm-6">
                            <div class="form-group">
                                <label for="form_name">Who is primary policy holder? *</label>
                                <select v-model="applicationData.insurancePolicyHolder" class="form-control custom-select" name="gender" id="gender">
                                    <option value="">Select</option>
                                    <option v-for="(data) in accountHolderTypes" :key="data.key" :value="data.key">{{data.label}}</option>
                                </select>
                                <div v-if="validationErrors.insurancePolicyHolder != undefined" class="mt-1 mb-2 vuelidate-error">{{ validationErrors.insurancePolicyHolder }}</div>
                            </div>
                        </div>
                        <div class="col-md-4 col-sm-6">
                            <div class="form-group">
                                <label for="ssn">Policy Holder SSN No *</label>
                                <input-mask class="form-control" v-model="applicationData.ssnNo" mask="9999999999" placeholder="Please enter SSN Number" maskChar=""></input-mask>
                                <div v-if="validationErrors.ssnNo != undefined" class="mt-1 mb-2 vuelidate-error">{{ validationErrors.ssnNo }}</div>
                            </div>
                        </div>
                        <div class="col-md-4 col-sm-6">
                            <div class="form-group">
                                <label for="ssn">Insurance card Front photo *</label>
                                <input type="file" ref="insuranceProof1" id="insuranceProof1" accept=".png,.jpg,.jpeg" v-on:change="handleUpload('proof1')">
                                <!-- <div v-if="applicationData.insuranceProof1DownloadLink != '' && applicationData.insuranceProof1==null" class="mt-1 mb-2"><a :href="applicationData.insuranceProof1DownloadLink" target="_blank">Download</a></div> -->
                                <div v-if="validationErrors.insuranceProof1 != undefined" class="mt-1 mb-2 vuelidate-error">{{ validationErrors.insuranceProof1 }}</div>
                            </div>
                        </div>
                        <div class="col-md-4 col-sm-6">
                            <div class="form-group">
                                <label for="ssn">Insurance card Back photo *</label>
                                <input type="file" ref="insuranceProof2" id="insuranceProof2" accept=".png,.jpg,.jpeg" v-on:change="handleUpload('proof2')">
                                <!-- <div v-if="applicationData.insuranceProof2DownloadLink != '' && applicationData.insuranceProof2==null" class="mt-1 mb-2"><a :href="applicationData.insuranceProof2DownloadLink" target="_blank">Download</a></div> -->
                                <div v-if="validationErrors.insuranceProof2 != undefined" class="mt-1 mb-2 vuelidate-error">{{ validationErrors.insuranceProof2 }}</div>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-12 question__answer">
                            <input type="checkbox" id="testApplicationAgreement" @click="agreeTerms" :checked="isAgreed==1 ? true: false" >
                            <label for="testApplicationAgreement">I agree to the Terms and Conditions</label>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="text-center mb-5">
            <button type="button" class="bttn bttn--secondary mx-2" @click="triggerPreviousAction">Back</button>
            <button type="button" class="bttn bttn--primary mx-2" @click="triggerNextAction">Next</button>
        </div>
    </section>
</template>
<script>
export default {
    props:{
        patientProfile:Object,
        applicationData:Object,
        validationErrors:Object
    },
    data() {
        return {
            selected: 'medical',
            pregnancyStates:this.$store.getters.getPregnancyStates,
            testTypes:this.$store.getters.getTestTypes,
            accountHolderTypes:this.$store.getters.getAccountHolderTypes,
            isAgreed:0
        }
    },
    mounted() {
        
    },
    methods: {
        handleUpload(fileString){
            switch(fileString) {
                case 'proof1':
                    this.applicationData.insuranceProof1 = this.$refs.insuranceProof1.files[0];
                    break;
                case 'proof2':
                    this.applicationData.insuranceProof2 = this.$refs.insuranceProof2.files[0];
                    break;
                default:
                    break;
            }
        },
        agreeTerms(){
            this.isAgreed = (this.isAgreed==0)?1:0;
        },
        triggerNextAction(){
            if(this.isAgreed==0){
                this.$awn.alert("Please agree to our Terms and Conditions before proceeding");
            }
            else{
                this.$emit('trigger-next');
            }
        },
        triggerPreviousAction(){
            this.$emit('trigger-previous');
        }
    },
}
</script>