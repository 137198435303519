import BarcodeRegistration from '../components/KitActivation/BarcodeRegistration';
import ActivationFDADisclaimer from '../components/KitActivation/ActivationFDADisclaimer';
import ActivationFactSheet from '../components/KitActivation/ActivationFactSheet';
import ActivationQuestionnaire from '../components/KitActivation/ActivationQuestionnaire';
import ActivationApplication from '../components/KitActivation/ActivationApplication';
import ActivationApplicationPreview from '../components/KitActivation/ActivationApplicationPreview';

export default [
    {
		path: '/testkit/activate',
		name: 'kitactivation',
		component: BarcodeRegistration,
		meta: { layout: 'dashboard'}
	},
	{
		path: '/testkit/fdadisclaimer',
		name: 'testkit_fdadisclaimer',
		component: ActivationFDADisclaimer,
		meta: { layout: 'dashboard'}
	},
    {
		path: '/testkit/factsheet',
		name: 'testkit_factsheet',
		component: ActivationFactSheet,
		meta: { layout: 'dashboard'}
	},
    {
		path: '/testkit/questions',
		name: 'testkit_questions',
		component: ActivationQuestionnaire,
		meta: { layout: 'dashboard'}
	},
	{
		path: '/testkit/application',
		name: 'testkit_application',
		component: ActivationApplication,
		meta: { layout: 'dashboard'}
	},
	{
		path: '/testkit/application/preview',
		name: 'teskit_application_preview',
		component: ActivationApplicationPreview,
		meta: { layout: 'dashboard'}
	}
];
