<template>
    <section class="application">
        <div class="container">
            <div class="question">
                <div class="question__header">
                    <h4>Profile Information</h4>
                </div>
                <div class="question__body">
                    <div class="row">
                        <div class="col-md-4 col-sm-6">
                            <div class="form-group">
                                <label for="first_name">First Name *</label>
                                <input v-model="userProfile.firstName" id="first_name" type="text" name="first_name" class="form-control" placeholder="Please enter your First Name">
                                <div v-if="validationErrors.firstName != undefined" class="mt-1 mb-2 vuelidate-error">{{ validationErrors.firstName }}</div>
                            </div>
                        </div>
                        <div class="col-md-4 col-sm-6">
                            <div class="form-group">
                                <label for="new_password">Middle Name</label>
                                <input v-model="userProfile.middleName" id="middle_name" type="text" name="middle_name" class="form-control" placeholder="Please enter your Middle Name">
                                <div v-if="validationErrors.middleName != undefined" class="mt-1 mb-2 vuelidate-error">{{ validationErrors.middleName }}</div>
                            </div>
                        </div>
                        <div class="col-md-4 col-sm-6">
                            <div class="form-group">
                                <label for="new_password">Last Name *</label>
                                <input v-model="userProfile.lastName" id="last_name" type="text" name="last_name" class="form-control" placeholder="Please enter your Last Name">
                                <div v-if="validationErrors.lastName != undefined" class="mt-1 mb-2 vuelidate-error">{{ validationErrors.lastName }}</div>
                            </div>
                        </div>
                        <div class="col-md-4 col-sm-6">
                            <div class="form-group">
                                <label for="new_password">Email *</label>
                                <input v-model="userProfile.email" id="email" type="text" name="email" class="form-control" placeholder="Please enter your Email">
                                <div v-if="validationErrors.email != undefined" class="mt-1 mb-2 vuelidate-error">{{ validationErrors.email }}</div>
                            </div>
                        </div>
                        <div class="col-md-4 col-sm-6">
                            <div class="form-group">
                                <label for="new_password">Date of Birth *</label>
                                <input-mask class="form__input form-control" v-model="userProfile.dob" mask="99-99-9999" placeholder="MM-DD-YYYY" maskChar="_"></input-mask>
                                <div v-if="validationErrors.dob != undefined" class="mt-1 mb-2 vuelidate-error">{{ validationErrors.dob }}</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="text-center mb-5">
            <button type="button" class="bttn bttn--primary mx-2" @click="processProfileUpdate">Update</button>
        </div>
    </section>
</template>
<script>
export default {
    data() {
        return {
            userProfile:{
                id:this.$store.getters.getUserData.id,
                firstName:this.$store.getters.getUserData.firstName,
                middleName:this.$store.getters.getUserData.middleName,
                lastName:this.$store.getters.getUserData.lastName,
                dob:this.$store.getters.getUserData.dob,
                email:this.$store.getters.getUserData.email
            },
            validationErrors:{}
        }
    },
    mounted() {
        console.log(this.$store.getters.getUserData);
    },
    methods: {
        processProfileUpdate(){
            this.validationErrors = {};
            axios.post(this.$store.getters.getAPIBasePath + '/profile', this.userProfile)
            .then(response => {
                this.$awn.success(response.data.headers.message);
                this.reloadUserData();
                this.$router.push("/dashboard");
            })
            .catch(error => {
                if(!error.response.data.headers.length){
                    this.$awn.alert(error.response.data.headers.message);
                }
                if(!error.response.data.errorlist.length){
                    this.validationErrors = error.response.data.errorlist;
                }
            });
        },
        reloadUserData(){
            axios.get(this.$store.getters.getAPIBasePath + '/profile')
            .then(response => {
                this.$store.commit('saveUserData',response.data.recordinfo);
            })
            .catch(error => {
                console.log(error.response.data);
            });
        }
    }
}
</script>