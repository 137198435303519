<template>
    <div class="login-container" v-if="isKeyVerified==1">
        <div class="container col-md-6">
            <div class="login-container__inner p-5">
                <div class="login-header">
                    <h3>Reset Password</h3>
                    <img src="../../assets/images/logo.png" width="200px" alt="">
                </div>
                <div class="row align-items-center flex-column">
                    <div class="col-md-6 col-sm-12">
                        <div class="form-group" :class="{ 'form-group--error': $v.reset.newPassword.$error }">
                            <label class="form__label" for="password">Password *</label>
                            <input  type="password" v-model="reset.newPassword" id="password" class="form-control form__input" placeholder="Password *"/>
                            <div v-if="resetErrors.newPassword != undefined" class="mt-1 mb-2 vuelidate-error">{{ resetErrors.newPassword }}</div>
                            <div class="mt-1 mb-2 vuelidate-error" v-if="$v.reset.newPassword.$error && !$v.reset.newPassword.required">Password is required.</div>
                            <div class="mt-1 mb-2 vuelidate-error" v-if="$v.reset.newPassword.$error && !$v.reset.newPassword.minLength">Password must have at least {{ $v.reset.newPassword.$params.minLength.min }} letters.</div>
                            <div class="mt-1 mb-2 vuelidate-error" v-if="$v.reset.newPassword.$error && !$v.reset.newPassword.maxLength">Password cannot exceed {{ $v.reset.newPassword.$params.maxLength.max }} letters.</div>
                        </div>
                    </div>
                    <div class="col-md-6 col-sm-12">
                        <div class="form-group" :class="{ 'form-group--error': $v.reset.confirmPassword.$error }">
                            <label class="form__label" for="confirm_password">Confirm Password *</label>
                            <input type="password" v-model="reset.confirmPassword" id="confirm_password" class="form-control form__input" placeholder="Confirm Password *"/>
                            <div v-if="resetErrors.confirmPassword != undefined" class="mt-1 mb-2 vuelidate-error">{{ resetErrors.confirmPassword }}</div>
                            <div class="mt-1 mb-2 vuelidate-error" v-if="$v.reset.confirmPassword.$error && !$v.reset.confirmPassword.required">Password is required.</div>
                            <div class="mt-1 mb-2 vuelidate-error" v-if="$v.reset.confirmPassword.$error && !$v.reset.confirmPassword.minLength">Password must have at least {{ $v.reset.confirmPassword.$params.minLength.min }} letters.</div>
                            <div class="mt-1 mb-2 vuelidate-error" v-if="$v.reset.confirmPassword.$error && !$v.reset.confirmPassword.maxLength">Password cannot exceed {{ $v.reset.confirmPassword.$params.maxLength.max }} letters.</div>
                            <div class="mt-1 mb-2 vuelidate-error" v-if="$v.reset.confirmPassword.$error && !$v.reset.confirmPassword.sameAs">Both Passwords must be identical.</div>
                        </div>
                    </div>
                </div>
                <div class="text-center mb-5">
                    <button type="button" @click="performResetRequest()" class="bttn bttn--primary mx-2">Reset Password</button>
                </div>
                <div class="text-center">
                    <router-link class="ForgetPwd" :to="{ name: 'login' }">Click here to Login</router-link>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import { required, minLength, maxLength, sameAs } from 'vuelidate/lib/validators';
export default {
    computed: {
        resetkey() {
            return this.$route.params.resetkey;
        }
    },
    data() {
        return {
            isKeyVerified:0,
            reset: {
                resetKey:'',
                newPassword: '',
                confirmPassword:''
            },
            resetErrors: {}
        }
    },
    validations: { 
        reset: {
            newPassword: {
                required,
                minLength: minLength(6),
                maxLength: maxLength(20)
            },
            confirmPassword: {
                required,
                minLength: minLength(6),
                maxLength: maxLength(20),
                sameAsPassword: sameAs('newPassword')
            }
        }
    },
    mounted(){
        this.verifyKey();
    },
    methods: {
        verifyKey(){
            axios.get(this.$store.getters.getAPIBasePath + '/auth/verify/passwordreset/'+this.resetkey)
            .then(response => {
                let apiResponse = response.data;
                this.isKeyVerified = 1;
                this.reset.resetKey = this.resetkey;
            })
            .catch(error => {
                if(!error.response.data.headers.length){
                    this.$awn.alert(error.response.data.headers.message);
                }
                this.$router.push('/login');
            });
        },
        performResetRequest() {
            this.resetErrors = {};
            this.$v.reset.$touch();
            if (!this.$v.reset.$invalid) {
                axios.post(this.$store.getters.getAPIBasePath + '/auth/passwordreset', this.reset)
                .then(response => {
                    this.$awn.success(response.data.headers.message);
                    this.$router.push('/login');
                })
                .catch(error => {
                    if(!error.response.data.headers.length){
                        this.$awn.alert(error.response.data.headers.message);
                    }
                    if(!error.response.data.errorlist.length){
                        this.resetErrors = error.response.data.errorlist;
                    }
                });
            }
            else{
                console.log('show error');
            }
        }
    }
}

</script>
