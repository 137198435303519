<template>
    <div class="login-container">
        <div class="container ">
            <div class="login-container__inner p-5">
                <div class="login-header">
                    <h3>Register</h3>
                    <img src="../../assets/images/logo.png" width="200px" alt="">
                </div>
                <div class="row">
                    <div class="col-md-4 col-sm-6">
                        <div class="form-group" :class="{ 'form-group--error': $v.register.firstName.$error }">
                            <label class="form__label">First Name</label>
                            <input class="form__input form-control" v-model="register.firstName" type="text" placeholder="First Name *"/>
                            <div v-if="registrationErrors.firstName != undefined" class="mt-1 mb-2 vuelidate-error">{{ registrationErrors.firstName }}</div>
                            <div class="mt-1 mb-2 vuelidate-error" v-if="$v.register.firstName.$error && !$v.register.firstName.required">First Name Required</div>
                            <div class="mt-1 mb-2 vuelidate-error" v-if="$v.register.firstName.$error && !$v.register.firstName.minLength">First Name must have atleast {{$v.register.firstName.$params.minLength.min}} letters.</div>
                            <div class="mt-1 mb-2 vuelidate-error" v-if="$v.register.firstName.$error && !$v.register.firstName.required">First Name cannot exceed {{$v.register.firstName.$params.maxLength.max}} letters.</div>
                        </div>
                    </div>
                    <div class="col-md-4 col-sm-6">
                        <div class="form-group" :class="{ 'form-group--error': $v.register.middleName.$error }">
                            <label class="form__label" for="middlename">Middle Name</label>
                            <div v-if="registrationErrors.middleName != undefined" class="mt-1 mb-2 vuelidate-error">{{ registrationErrors.middleName }}</div>
                            <input class="form__input form-control" v-model="register.middleName" type="text" id="middlename" placeholder="Middle Name"/>
                            <div class="mt-1 mb-2 vuelidate-error" v-if="$v.register.middleName.$error && !$v.register.middleName.maxLength">Middle Name cannot exceed {{$v.register.middleName.$params.maxLength.max}} letters.</div>
                        </div>
                    </div>
                    <div class="col-md-4 col-sm-6">
                        <div class="form-group" :class="{ 'form-group--error': $v.register.lastName.$error }">
                            <label class="form__label" for="lastname">Last Name *</label>
                            <input type="text" id="lastname" class="form__input form-control" v-model="register.lastName" placeholder="Last Name *"/>
                            <div v-if="registrationErrors.lastName != undefined" class="mt-1 mb-2 vuelidate-error">{{ registrationErrors.lastName }}</div>
                            <div class="mt-1 mb-2 vuelidate-error" v-if="$v.register.lastName.$error && !$v.register.lastName.required">Last Name is required.</div>
                            <div class="mt-1 mb-2 vuelidate-error" v-if="$v.register.lastName.$error && !$v.register.lastName.minLength">Last Name must have atleast {{$v.register.lastName.$params.minLength.min}} letters.</div>
                            <div class="mt-1 mb-2 vuelidate-error" v-if="$v.register.lastName.$error && !$v.register.lastName.maxLength">Last Name cannot exceed {{$v.register.lastName.$params.maxLength.max}} letters.</div>
                        </div>
                    </div>
                    <div class="col-md-4 col-sm-6">
                        <div class="form-group" :class="{ 'form-group--error': $v.register.dob.$error }">
                            <label class="form__label" for="dob">DOB *</label>
                            <input-mask class="form__input form-control" v-model="register.dob" mask="99-99-9999" placeholder="MM-DD-YYYY" maskChar="_"></input-mask>
                            <div v-if="registrationErrors.dob != undefined" class="mt-1 mb-2 vuelidate-error">{{ registrationErrors.dob }}</div>
                            <div class="mt-1 mb-2 vuelidate-error" v-if="$v.register.dob.$error && !$v.register.dob.required">Date of Birth is required.</div>
                        </div>
                    </div>
                    <div class="col-md-4 col-sm-6">
                        <div class="form-group" :class="{ 'form-group--error': $v.register.email.$error }">
                            <label class="form__label" for="email">Email *</label>
                            <input type="text" v-model="register.email" id="email" class="form-control" placeholder="Email *"/>
                            <div v-if="registrationErrors.email != undefined" class="mt-1 mb-2 vuelidate-error">{{ registrationErrors.email }}</div>
                            <div class="mt-1 mb-2 vuelidate-error" v-if="$v.register.email.$error && !$v.register.email.required">Email is required.</div>
                            <div class="mt-1 mb-2 vuelidate-error" v-if="$v.register.email.$error && !$v.register.email.email">Please enter valid email.</div>
                            <div class="mt-1 mb-2 vuelidate-error" v-if="$v.register.email.$error && !$v.register.email.maxLength">Email must have at most {{ $v.register.email.$params.maxLength.max }} letters.</div>
                        </div>
                    </div>
                    <div class="col-md-4 col-sm-6">
                        <div class="form-group" :class="{ 'form-group--error': $v.register.confirmEmail.$error }">
                            <label class="form__label" for="confirm_email">Confirm Email *</label>
                            <input type="text" v-model="register.confirmEmail" id="confirm_email" class="form-control" placeholder="Confirm Email *"/>
                            <div v-if="registrationErrors.confirmEmail != undefined" class="mt-1 mb-2 vuelidate-error">{{ registrationErrors.confirmEmail }}</div>
                            <div class="mt-1 mb-2 vuelidate-error" v-if="$v.register.confirmEmail.$error && !$v.register.confirmEmail.required">Email is required.</div>
                            <div class="mt-1 mb-2 vuelidate-error" v-if="$v.register.confirmEmail.$error && !$v.register.confirmEmail.email">Please enter valid email.</div>
                            <div class="mt-1 mb-2 vuelidate-error" v-if="$v.register.confirmEmail.$error && !$v.register.confirmEmail.maxLength">Email cannot exceed {{ $v.register.confirmEmail.$params.maxLength.max }} letters.</div>
                            <div class="mt-1 mb-2 vuelidate-error" v-if="$v.register.confirmEmail.$error && !$v.register.confirmEmail.sameAs">Both Emails must be identical.</div>
                        </div>
                    </div>
                    <div class="col-md-4 col-sm-6">
                        <div class="form-group" :class="{ 'form-group--error': $v.register.password.$error }">
                            <label class="form__label" for="password">Password *</label>
                            <input  type="password" v-model="register.password" id="password" class="form-control form__input" placeholder="Password *"/>
                            <div v-if="registrationErrors.password != undefined" class="mt-1 mb-2 vuelidate-error">{{ registrationErrors.password }}</div>
                            <div class="mt-1 mb-2 vuelidate-error" v-if="$v.register.password.$error && !$v.register.password.required">Password is required.</div>
                            <div class="mt-1 mb-2 vuelidate-error" v-if="$v.register.password.$error && !$v.register.password.minLength">Password must have at least {{ $v.register.password.$params.minLength.min }} letters.</div>
                            <div class="mt-1 mb-2 vuelidate-error" v-if="$v.register.password.$error && !$v.register.password.maxLength">Password cannot exceed {{ $v.register.password.$params.maxLength.max }} letters.</div>
                        </div>
                    </div>
                    <div class="col-md-4 col-sm-6">
                        <div class="form-group" :class="{ 'form-group--error': $v.register.confirmPassword.$error }">
                            <label class="form__label" for="confirm_password">Confirm Password *</label>
                            <input type="password" v-model="register.confirmPassword" id="confirm_password" class="form-control form__input" placeholder="Confirm Password *"/>
                            <div v-if="registrationErrors.confirmPassword != undefined" class="mt-1 mb-2 vuelidate-error">{{ registrationErrors.confirmPassword }}</div>
                            <div class="mt-1 mb-2 vuelidate-error" v-if="$v.register.confirmPassword.$error && !$v.register.confirmPassword.required">Password is required.</div>
                            <div class="mt-1 mb-2 vuelidate-error" v-if="$v.register.confirmPassword.$error && !$v.register.confirmPassword.minLength">Password must have at least {{ $v.register.confirmPassword.$params.minLength.min }} letters.</div>
                            <div class="mt-1 mb-2 vuelidate-error" v-if="$v.register.confirmPassword.$error && !$v.register.confirmPassword.maxLength">Password cannot exceed {{ $v.register.confirmPassword.$params.maxLength.max }} letters.</div>
                            <div class="mt-1 mb-2 vuelidate-error" v-if="$v.register.confirmPassword.$error && !$v.register.confirmPassword.sameAs">Both Passwords must be identical.</div>
                        </div>
                    </div>
                </div>
                <div class="text-center mb-5">
                    <button type="button" @click="registerUser()" class="bttn bttn--primary mx-2">Register</button>
                </div>
                <div class="text-center">
                    <router-link class="ForgetPwd" :to="{ name: 'login' }">If you have already registered, Click here to Login</router-link>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import { required, minLength, maxLength, email, sameAs } from 'vuelidate/lib/validators';
import moment from 'moment'
export default {
    data() {
        return {
            register: {
                firstName: '',
                middleName: '',
                lastName:'',
                email:'',
                confirmEmail:'',
                dob:'',
                password:'',
                confirmPassword:''
            },
            registrationErrors: {}
        }
    },
    validations: {
        register: {
            firstName: {
                required,
                minLength: minLength(2),
                maxLength: maxLength(50)
            },
            middleName: {
                maxLength: maxLength(50)
            },
            lastName: {
                required,
                minLength: minLength(1),
                maxLength: maxLength(50)
            },
            email: {
                required,
                email,
                maxLength: maxLength(250) 
            },
            confirmEmail: {
                required,
                email,
                maxLength: maxLength(250),
                sameAsEmail: sameAs('email')
            },
            dob: {
                required
            },
            password: {
                required,
                minLength: minLength(6),
                maxLength: maxLength(20)
            },
            confirmPassword: {
                required,
                minLength: minLength(6),
                maxLength: maxLength(20),
                sameAsPassword: sameAs('password')
            }
        }
    },
    mounted() {

    },
    methods: {
        registerUser() {
            this.registrationErrors = {};
            this.$v.register.$reset();
            this.$v.register.$touch();
            if (!this.$v.register.$invalid) {
                axios.post(this.$store.getters.getAPIBasePath + '/auth/register', this.register)
                .then(response => {
                    this.$awn.success("Your registration is complete. Please proceed by logging in");
                    this.$router.push("/login");
                })
                .catch(error => {
                    if(!error.response.data.headers.length){
                        this.$awn.alert(error.response.data.headers.message);
                    }
                    if(!error.response.data.errorlist.length){
                        this.registrationErrors = error.response.data.errorlist;
                    }
                });
            }
        },
    }
}

</script>