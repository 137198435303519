<template>
    <div class="login-container">
        <div class="container col-md-6">
            <div class="login-container__inner p-5">
                <div class="login-header">
                    <h3>Password Recovery</h3>
                    <img src="../../assets/images/logo.png" width="200px" alt="">
                </div>
                <div class="row justify-content-center">
                    <div class="col-md-6 col-sm-12">
                        <div class="form-group" :class="{ 'form-group--error': $v.verify.email.$error }">
                            <label class="form__label" for="email">Email *</label>
                            <input type="text" v-model="verify.email" id="email" class="form-control" placeholder="Email *"/>
                            <div v-if="verifyErrors.email != undefined" class="mt-1 mb-2 vuelidate-error">{{ verifyErrors.email }}</div>
                            <div class="mt-1 mb-2 vuelidate-error" v-if="$v.verify.email.$error && !$v.verify.email.required">Email is required.</div>
                            <div class="mt-1 mb-2 vuelidate-error" v-if="$v.verify.email.$error && !$v.verify.email.email">Please enter valid email.</div>
                            <div class="mt-1 mb-2 vuelidate-error" v-if="$v.verify.email.$error && !$v.verify.email.maxLength">Email must have at most {{ $v.verify.email.$params.maxLength.max }} letters.</div>
                        </div>
                    </div>
                </div>
                <div class="text-center mb-5">
                    <button type="button" @click="performResetRequest()" class="bttn bttn--primary mx-2">Reset Password</button>
                </div>
                <div class="text-center">
                    <router-link class="ForgetPwd" :to="{ name: 'login' }">If you have already registered, Click here to Login</router-link>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import { encryptData } from '../../helpers/AuthUtils';
import { required, minLength, maxLength, email } from 'vuelidate/lib/validators';
export default {
    data() {
        return {
            verify: {
                email: '',
            },
            verifyErrors: {}
        }
    },
    validations: { 
        verify: {
            email: {
                required,
                email,
                maxLength: maxLength(250) 
            }
        }
    },
    mounted() {},
    methods: {
        performResetRequest() {
            this.verifyErrors = {};
            this.$v.verify.$touch();
            if (!this.$v.verify.$invalid) {
                axios.post(this.$store.getters.getAPIBasePath + '/auth/forgetpassword', this.verify)
                .then(response => {
                    this.$awn.success(response.data.headers.message);
                    this.$router.push('/login');
                })
                .catch(error => {
                    if(!error.response.data.headers.length){
                        this.$awn.alert(error.response.data.headers.message);
                    }
                    if(!error.response.data.errorlist.length){
                        this.verifyErrors = error.response.data.errorlist;
                    }
                });
            }
            else{
                console.log('show error');
            }
        }
    }
}

</script>
