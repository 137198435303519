<template>
	<section>
		<div class="container">
			<div class="text-center form-header">
				<h3 class="form-heading">Welcome to your Dashboard</h3>
				<!-- <p class="form-desc">
				Please register new kits by clicking Register Kit above. Completed
				reports will display here.
				</p> -->
				<!-- <a class="form-link" href="">Fact Sheet for Patients</a> -->
			</div>
			<div class="messages"></div>
			<div class="row justify-content-center mb-5">
				<div class="col-md-4 sol-sm-6">
					<div class="form-group">
						<label for="form_name">Select Patient *</label>
						<select class="form-control custom-select" v-model="currentPatient" @change="loadPatientApplications()" name="patientProfile" id="patientProfile">
							<option v-for="(data) in patientProfiles" :key="data.id" :value="data.id">{{data.firstName}} {{data.lastName}}</option>
						</select>
					</div>
				</div>
				<div class="col-md-2 sol-sm-2">
					<input type="submit" @click="navigateToAddPatient" class="bttn bttn--primary bttn--dashboard bttn--dasher" value="Add Patient" />
				</div>
			</div>
			<div class="row justify-content-center">
				<div class="col-md-6 sol-sm-6">
					<router-link :to="{ name: 'patientedit', params: { patientid: currentPatient }}">Edit Patient</router-link>
				</div>
			</div>
			<div class="row justify-content-center mt-6">
				<div class="col-md-12 sol-sm-6">
					<table class="table table-bordered table-striped table-responsive-stack">
						<thead class="thead-light">
							<tr>
								<th>Patient Name</th>
								<th>Applied Date</th>
								<th>Application Status</th>
								<th>Tested Result</th>
							</tr>
						</thead>
						<tbody>
							<tr v-if="applications.length==0"><td colspan="4">No applications found</td></tr>
							<tr v-for="(appInfo,index) in applications" :key="index">
								<td>{{appInfo.patientInfo.firstName}} {{appInfo.patientInfo.lastName}}</td>
								<td>{{appInfo.appliedDate}}</td>
								<td v-if="appInfo.applicationStatus=='pending'">Submitted</td>
								<td v-if="appInfo.applicationStatus=='activated'">Kit Activated</td>
								<td v-if="appInfo.applicationStatus=='completed'">Completed</td>
								<td v-if="appInfo.applicationStatus=='pending'">Kit Activation Pending</td>
								<td v-if="appInfo.applicationStatus=='activated'">Result Pending</td>
								<td v-if="appInfo.applicationStatus=='completed'"><a href="Javascript:void(0);" @click="dowloadReport(appInfo.id)">Download Report</a></td>
							</tr>
						</tbody>
					</table>
				</div>
			</div>
		</div>
	</section>
</template>
<script>
export default {
	data() {
		return {
			currentPatient:'',
			patientProfiles:[],
			applications:[]
		};
	},
	mounted(){
		this.loadPatientProfiles();
	},
	methods:{
		loadPatientProfiles() {
            axios.get(this.$store.getters.getAPIBasePath + '/patientprofiles')
            .then(response => {
				let profiles = response.data.data;
				if(!profiles.length){
					this.$router.push("/patients/add");
				}
				else{
					this.patientProfiles = profiles;
					this.currentPatient = profiles[0].id;
					this.loadPatientApplications();
				}
            })
            .catch(error => {
                console.log(error);
            });
        },
		loadPatientApplications() {
            axios.get(this.$store.getters.getAPIBasePath + '/applications/viewall/'+this.currentPatient)
            .then(response => {
				this.applications = response.data.applications;
				console.log(this.applications);
            })
            .catch(error => {
                console.log(error);
            });
        },
		navigateToAddPatient(){
			this.$router.push("/patients/add")
		},
		dowloadReport(applicationId){
			axios.get(this.$store.getters.getAPIBasePath+'/applications/download/report/'+applicationId,{responseType: 'arraybuffer'})
            .then(response => {
				this.$awn.warning('Please allow popups when prompted to download Report');
            	let blob = new Blob([response.data], { type: 'application/pdf' });
        		let url = window.URL.createObjectURL(blob);
        		window.open(url);
            })
            .catch(error => {
            	console.log(error.response.data);
            	this.$awn.alert('Error occured while downloading report.');
            });
		}
	}
};
</script>