<template>
    <FactSheet @trigger-next="goNext()" @trigger-previous="goPrevious()" />
</template>

<script>
    import FactSheet from "../CommonElements/FactSheet";
    export default {
        components: {
            FactSheet,
        },
        mounted(){
            if(!this.$store.getters.isActivationApplicationLoaded || this.$store.getters.getRecentActivationApplicationStatus!='draft'){
                this.$router.push("/testkit/activate");
            }
        },
        methods:{
            goPrevious(){
                this.$router.push("/testkit/fdadisclaimer");
            },
            goNext(){
                this.$router.push("/testkit/questions");
            }
        }
    };
</script>