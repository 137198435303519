// @flow
import jwtDecode from 'jwt-decode';
import CryptoJS from 'crypto-js';
import axios from 'axios';
// const cookies = new Cookies();
const BASE_URL = process.env.VUE_APP_BASE_URL;


const authHeader = () => {
    const userAccessToken = getUserData('accesstoken');
    return {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + userAccessToken
    }
}

const mutlipartHeader = () => {
    const userAccessToken = getUserData('accesstoken');
    return {
        'Authorization': 'Bearer ' + userAccessToken
    }
}


/**
 * Checks if user is authenticated
 */
const isUserAuthenticated = () => {
    let user = getLoggedInUser();
    if (!user || !user.accesstoken) {
        return false;
    }
    else {
        const decoded = jwtDecode(user.accesstoken);
        const currentTime = Date.now() / 1000;
        if (decoded.exp < currentTime) {
            console.warn('access token expired');
            // refreshToken(user.refreshkey);
            // do logout
            return false;
        } else {
            return true;
        }
    }
};

/**
 * Refresh page
 */
const refreshPage = () => {
    window.location.reload();
};

/**
 * Returns the logged in user
 */
const getLoggedInUser = () => {
    const user = localStorage.getItem('client');
    let jsonData = user ? decryptData(user) : null;
    return jsonData ? (typeof jsonData == 'object' ? jsonData : JSON.parse(jsonData)) : null;
};

const getUserData = (key) => {
    let user = getLoggedInUser();
    return user ? user[key] : null;
}

/**
 * Clear Session and logout the user
 */
const clearSession = () => {
    //localStorage.clear();
    localStorage.removeItem('client');
    refreshPage();
};

const refreshToken = () => {
    const config = {
        headers: { 'Content-Type': 'application/json' }
    };
    let refreshkey = getUserData('refreshkey');
    const bodyParameters = { refreshkey: refreshkey }
    axios.post(BASE_URL + 'auth/refresh', bodyParameters, config)
    .then((response) => {
        if (response.status !== 200) {
            // ??? do logout
            // store.dispatch("doLogout");
            // router.push("/login");
        } else {
            jsonResponse.authenticationheaders.refreshkey = refreshkey;
            let sessionData = Object.assign({}, jsonResponse.authenticationheaders, jsonResponse.profiledata);
            localStorage.setItem('client', encryptData(sessionData));
        }
    }).catch(error=>console.warn(error));
}

/**
 * Random String Generator
 */
const genRandomString = () => {
    return Math.random().toString(36).slice(2);
};

/**
 * Data Encryption
 */
const encryptData = (data) => {
    let salt = process.env.VUE_APP_PROJECT_SALT;
    if (data && salt) return CryptoJS.AES.encrypt(data, salt).toString();
    else return null;
};

/**
 * Data Decryption
 */
const decryptData = (data) => {
    let salt = process.env.VUE_APP_PROJECT_SALT;
    if (data && salt) {
        let bytes = CryptoJS.AES.decrypt(data, salt);
        return bytes.toString(CryptoJS.enc.Utf8);
    } else {
        return null;
    }
}

const setToken = () => {
    let token = genRandomString();
    localStorage.setItem('token', token);
}

export { isUserAuthenticated, getLoggedInUser, refreshToken, encryptData, decryptData,setToken, getUserData, authHeader,  mutlipartHeader };
