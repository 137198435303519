import Login from '../components/Auth/Login';
import Register from '../components/Auth/Register';
import ForgotPassword from '../components/Auth/ForgotPassword';
import ResetPassword from '../components/Auth/ResetPassword';
import PatientDashboard from '../components/PatientProfile/PatientDashboard';

import patientroutes from './patientroutes';
import kitactivationroutes from './kitactivationroutes';
import testapplicationroutes from './testapplicationroutes';
import userprofileroutes from './userprofileroutes';

const baseroutes = [
	{
		path: '/',
		name: 'default',
		component: Login,
		meta: { layout: 'default'}
	},
	{
		path: '/login',
		name: 'login',
		component: Login,
		meta: { layout: 'default'}
	},
	{
		path: '/register',
		name: 'register',
		component: Register,
		meta: { layout: 'default'}
	},
	{
		path: '/forgotpassword',
		name: 'forgotpassword',
		component: ForgotPassword,
		meta: { layout: 'default'}
	},
	{
		path: '/resetpassword/:resetkey',
		name: 'resetpassword',
		component: ResetPassword,
		meta: { layout: 'default'}
	},
	{
		path: '/dashboard',
		name: 'dashboard',
		component: PatientDashboard,
		meta: { layout: 'dashboard'}
	}
];

const routes = baseroutes.concat(patientroutes,kitactivationroutes,testapplicationroutes,userprofileroutes);

export default routes;
