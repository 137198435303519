<template>
    <FDADisclaimer @trigger-next="goNext()" @trigger-previous="goPrevious()" />
</template>

<script>
    import FDADisclaimer from "../CommonElements/FDADisclaimer";
    export default {
        components: {
            FDADisclaimer,
        },
        mounted(){
            if(!this.$store.getters.isTestApplicationLoaded){
                this.$router.push("/test/profile");
            }
        },
        methods:{
            goPrevious(){
                this.$router.push("/test/profile");
            },
            goNext(){
                this.$router.push('/test/factsheet');
            }
        }
    };
</script>