<template>
    <section>
        <PatientForm v-if="infoLoaded==1" :patientInfo="patientInfo" :validationErrors="validationErrors" @on-submit="submitPatientProfile"></PatientForm>
    </section>
</template>
<script>
import PatientForm from "./Elements/PatientForm";
import moment from 'moment';
export default {
    components: {
        PatientForm,
    },
    data(){
        return {
            infoLoaded:0,
            patientInfo:{
                firstName:'',
                middleName:'',
                lastName:'',
                phoneNo:'',
                gender:'',
                dob:'',
                patientRelationship:'',
                race:'',
                ethnicity:'',
                address:'',
                city:'',
                state:'',
                zipCode:''
            },
            validationErrors:{}
        }
    },
    computed: {
        patientId() {
            return this.$route.params.patientid;
        }
    },
    mounted(){
        this.getPatientProfile();
    },
    methods: {
        getPatientProfile(){
            axios.get(this.$store.getters.getAPIBasePath + '/patientprofiles/view/'+this.patientId)
            .then(response => {
                let profile = response.data.recordinfo;
                this.patientInfo = {
                    firstName:profile.firstName,
                    middleName:profile.middleName,
                    lastName:profile.lastName,
                    phoneNo:profile.phoneNo,
                    gender:profile.gender,
                    dob:profile.dob,
                    patientRelationship:profile.relationToPatient,
                    race:profile.race,
                    ethnicity:profile.ethnicity,
                    address:profile.address,
                    city:profile.city,
                    state:profile.state,
                    zipCode:profile.zipCode
                };
                this.infoLoaded = 1;
            })
            .catch(error => {
                if(!error.response.data.headers.length){
                    this.$awn.alert(error.response.data.headers.message);
                }
                this.$router.push('/dashboard');
            });
        },
        submitPatientProfile(){
            axios.post(this.$store.getters.getAPIBasePath + '/patientprofiles/edit/'+this.patientId, this.patientInfo)
            .then(response => {
                this.$awn.success(response.data.headers.message);
                this.$router.push("/dashboard");
            })
            .catch(error => {
                if(!error.response.data.headers.length){
                    this.$awn.alert(error.response.data.headers.message);
                }
                if(!error.response.data.errorlist.length){
                    this.validationErrors = error.response.data.errorlist;
                }
            });
        }
    }
}
</script>