<template>
    <section class="application" v-if="isLoaded==1">
        <div class="container">
            <div class="question">
                <div class="question__header">
                    <h4>Patient Information</h4>
                </div>
                <div class="question__body">
                    <div class="row patient-info">
                        <div class="col-md-4 col-sm-6 patient-info__container">
                            <h5>First Name</h5>
                            <p>{{patientProfile.firstName}}</p>
                        </div>
                        <div class="col-md-4 col-sm-6 patient-info__container">
                            <h5>Middle Name </h5>
                            <p>{{patientProfile.middleName}}</p>
                        </div>
                        <div class="col-md-4 col-sm-6 patient-info__container">
                            <h5>Last Name</h5>
                            <p>{{patientProfile.lastName}}</p>
                        </div>
                        <div class="col-md-4 col-sm-6 patient-info__container">
                            <h5>Email</h5>
                            <p>{{patientProfile.email}}</p>
                        </div>
                        <div class="col-md-4 col-sm-6 patient-info__container">
                            <h5>Phone</h5>
                            <p>{{patientProfile.phoneNo}}</p>
                        </div>
                        <div class="col-md-4 col-sm-6 patient-info__container">
                            <h5>Address</h5>
                            <p>{{patientProfile.address}}, {{patientProfile.city}}, {{patientProfile.state}}-{{patientProfile.zipCode}} </p>
                        </div>
                        <div class="col-md-4 col-sm-6 patient-info__container">
                            <h5>Gender</h5>
                            <p>{{patientProfile.gender}}</p>
                        </div>
                        <div class="col-md-4 col-sm-6 patient-info__container">
                            <h5>Patient Relation</h5>
                            <p>{{patientProfile.relationToPatient}}</p>
                        </div>
                        <div class="col-md-4 col-sm-6 patient-info__container" v-if="patientProfile.gender!='Male'">
                            <h5>Pregnancy Status</h5>
                            <p>{{applicationData.pregnancyStatus}}</p>
                        </div>
                        <div class="col-md-4 col-sm-6 patient-info__container">
                            <h5>Test Type</h5>
                            <p>{{applicationData.testType}}</p>
                        </div>
                    </div>
                </div>
            </div>
            <div class="question">
                <div class="question__header">
                    <h4>Medical Information</h4>
                </div>
                <div class="question__body">
                    <div v-if="applicationData.proofType === 'other'" class="row">
                        <div class="col-md-4 col-sm-6 patient-info__container">
                            <h5>SSN</h5>
                            <p>{{applicationData.ssnNo}}</p>
                        </div>
                        <div class="col-md-4 col-sm-6 patient-info__container">
                            <h5>Driver license Number/state Id Number</h5>
                            <p>{{applicationData.drivingLicenseNo}}</p>
                        </div>
                    </div>
                    <div v-if="applicationData.proofType === 'insurance'" class="row">
                        <div class="col-md-4 col-sm-6 patient-info__container">
                            <h5>Insurance company</h5>
                            <p>{{applicationData.insuranceName}}</p>
                        </div>
                        <div class="col-md-4 col-sm-6 patient-info__container">
                            <h5>Insurance Phone No</h5>
                            <p>{{applicationData.insurancePhoneNo}}</p>
                        </div>
                        <div class="col-md-4 col-sm-6 patient-info__container">
                            <h5>Member No</h5>
                            <p>{{applicationData.insuranceMemberNo}}</p>
                        </div>
                        <div class="col-md-4 col-sm-6 patient-info__container">
                            <h5>Group No</h5>
                            <p>{{applicationData.insuranceGroupNo}}</p>
                        </div>
                        <div class="col-md-4 col-sm-6 patient-info__container">
                            <h5>Primary Policy Holder</h5>
                            <p>{{applicationData.insurancePolicyHolder}}</p>
                        </div>
                        <div class="col-md-4 col-sm-6 patient-info__container">
                            <h5>Policy Holder SSN No</h5>
                            <p>{{applicationData.ssnNo}}</p>
                        </div>
                        <div class="col-md-4 col-sm-6 patient-info__container">
                            <h5>Insurance Proof 1</h5>
                            <img :src="applicationData.insuranceProof1" width="100%"/>
                        </div>
                        <div class="col-md-4 col-sm-6 patient-info__container">
                            <h5>Insurance Proof 2</h5>
                            <img :src="applicationData.insuranceProof2" width="100%"/>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="text-center mb-5">
            <button type="button" class="bttn bttn--secondary mx-2" @click="triggerPreviousAction">Back</button>
            <button type="button" class="bttn bttn--primary mx-2" @click="triggerNextAction">Submit</button>
        </div>
    </section>
</template>
<script>
export default {
    props:{
        patientId:Number,
        applicationId:Number
    },
    data() {
        return {
            applicationData:{},
            patientProfile:{},
            isLoaded:0
        }
    },
    mounted() {
        this.loadPatientProfile();
        this.loadApplication();
    },
    methods: {
        loadPatientProfile(){
            axios.get(this.$store.getters.getAPIBasePath + '/patientprofiles/view/'+this.patientId)
            .then(response => {
                this.patientProfile = response.data.recordinfo;
            })
            .catch(error => {
                console.log(error);
            });
        },
        loadApplication(){
            axios.get(this.$store.getters.getAPIBasePath + '/applications/view/'+this.applicationId)
            .then(response => {
                this.applicationData = response.data.applicationPreview;
                this.isLoaded = 1;
            })
            .catch(error => {
                console.log(error);
            });
        },
        triggerNextAction(){
            this.$emit('trigger-next');
        },
        triggerPreviousAction(){
            this.$emit('trigger-previous');
        }
    },
}
</script>