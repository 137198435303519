import PatientRegistration from '../components/PatientProfile/PatientRegistration';
import PatientProfileEdit from '../components/PatientProfile/PatientProfileEdit';

export default [
    {
		path: '/patients/add',
		name: 'patientregistration',
		component: PatientRegistration,
		meta: {layout: 'dashboard'}
	},
	{
        path: "/patients/edit/:patientid",
        name: 'patientedit',
        meta: {layout: 'dashboard'},
        component: PatientProfileEdit
    }
];